import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { planbg } from "../utils/assets";
import axios from "axios";
import { BackendURL, WebsiteUrl, contractAddress } from "../utils/utils";
import { useStateContext } from "../context/AppContext";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import Web3 from "web3";
import ABI from "../utils/abi";

const ProjectDetail = () => {
  const { slug } = useParams();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [voted, setVoted] = useState();
  // localStorage.getItem("voted") === "true" || false
  const navigate = useNavigate();
  const { userData, connectMetaMask } = useStateContext();
  const [etherPrice, setEtherPrice] = useState(0);
  const [donations, setDonations] = useState(0);
  const [donors, setDonors] = useState(0);

  useEffect(() => {
    const user = JSON.parse(localStorage?.getItem("user"));
    // console.log(user, 'usrs')
    setLoading(true);
    axios
      .get(`${BackendURL}/projectsVote`)
      .then((res) => {
        const filterProject = res?.data?.filter(
          (x) => x.projects_slug === slug
        );
        console.log("filterProject", filterProject)
        setProjects(filterProject);
        axios
          .get(
            `${BackendURL}/vote/project/${filterProject[0]?.projects_id}/${user?.userId}`
          )
          .then((res) => {
            // console.log(res.data);
            getDonations(filterProject[0]?.projects_id)
            getDonors(filterProject[0]?.projects_id)
            // if (res.data.length > 0) {
            //   setVoted(true);
            //   // console.log(`voitting`);
            // }
          })
          .catch((error) => console.log(error));
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [slug]);

  const handleVotes = (projectId, projects_category_id) => {
    if (userData) {
      // axios
      //   .get(`${BackendURL}/vote/project/${projectId}/${userData?.userId}`)
      //   .then((res) => {
      //     if (res.data.length < 1) {
            // console.log(`voting`);
            const fetchRemainVotes = axios.get(
              `${BackendURL}/getRemainingVotes/${userData?.userId}`
            );
            const fetchMembership = axios.get(
              `${BackendURL}/getMembershipType/${userData?.userId}`
            );

            Promise.all([fetchRemainVotes, fetchMembership])
              .then(([remainVotesRes, membershipRes]) => {
                const membershipData = membershipRes?.data;
                const votesData = remainVotesRes?.data;
                let totalAllowedVotes = 0;
                let totalCastedVotes = 0;

                const remainingVotesForCategory = votesData.filter(
                  (vote) =>
                    vote.projects_category_id === projects_category_id
                );

                totalCastedVotes = remainingVotesForCategory.length;
                const filteredData = membershipData?.flatMap((element) => {
                  const allowedCategoriesArray = JSON.parse(
                    element.plan_numofvotes
                  );
                  const filteredAllowedCategories =
                    allowedCategoriesArray.filter(
                      (item) => item.category_id == projects_category_id
                    );
                  return filteredAllowedCategories?.map((category) => {
                    totalAllowedVotes += Number(category.numOfVotes)
                    const remainVotes =
                      parseInt(category.numOfVotes) -
                      remainingVotesForCategory.length;

                    return remainVotes;
                  });
                });
                const remainingVotes = totalAllowedVotes - totalCastedVotes;
                // console.log(`remainingVotes`, remainingVotes)
                if (remainingVotes > 0) {
                  const currentDate = new Date();
                  const formData = {
                    user_id: userData?.userId,
                    project_id: projectId,
                    project_date: currentDate.toISOString(),
                  };
                  axios
                    .post(`${BackendURL}/vote/project`, formData)
                    .then((res) => {
                      // console.log(res.data);
                      // setVoted(true);
                      // localStorage.setItem("voted", "true");
                      toast.success("Votted!", {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    })
                    .catch((error) => console.log(error));
                } else {
                  toast.info("Voting Quota reached to its limit, please purchase new plan!", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch((error) => console.log(error));
        //   } else {
        //     toast.info("Already Voted!", {
        //       position: toast.POSITION.TOP_RIGHT,
        //     });
        //   }
        // })
        // .catch((error) => console.log(error));
    } else {
      connectMetaMask();
      navigate("/signin");
      toast.dismiss("Sign In First", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // useEffect(() => {
  //   const user = JSON.parse(localStorage?.getItem("user"));
  //   console.log(user, 'user')
  //   if (user) {
  //     axios
  //       .get(
  //         `${BackendURL}/vote/project/${projects[0]?.projects_id}/${user?.userId}`
  //       )
  //       .then((res) => {
  //         console.log(res.data)
  //         if (res.data.length > 0) {
  //           setVoted(true);
  //           console.log(`voitting`)
  //         }
  //       })
  //       .catch((error) => console.log(error));
  //   } else {
  //     console.log(`working`);
  //   }
  // }, []);

  // console.log(projects, "projects");
  useEffect(() => {
    window.scrollTo(0, 0);
    getDollarValues();
  }, []);
  function getDonations(project_id) {
    axios.get(`${BackendURL}/donations/sum/${project_id}`)
      .then((res) => {
        // console.log("Donations: ", res.data)
        if (res.data.length > 0) {
          if (res.data[0].project_donation_amount != null) {
            setDonations(res.data[0].project_donation_amount)
          }
        }
      })
      .catch((error) => console.log(error));
  }
  function getDonors(project_id) {
    axios.get(`${BackendURL}/donations/count/${project_id}`)
      .then((res) => {
        // console.log("Donations: ", res.data)
        if (res.data.length > 0) {
          if (res.data[0].donors != null) {
            setDonors(res.data[0].donors)
          }
        }
      })
      .catch((error) => console.log(error));
  }
  function getDollarValues() {
    axios
      .get(
        `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum&order=market_cap_desc  &per_page=100&page=1&sparkline=false`
      )
      .then((response) => {
        setEtherPrice(response.data[1].current_price);
      })
      .catch((error) => console.log(error));
  }
  async function donateProject(walletAddress, projectPrice, projectPriceEther, projectId) {
    if (walletAddress == "" || walletAddress == null || walletAddress == undefined) {
      toast.error("Project Owner is not defined!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return
    }
    else if (projectPriceEther == "" || projectPriceEther == null || projectPriceEther == undefined) {
      toast.error("Project Price is not defined!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return
    }
    // const price = (parseFloat(projectPrice) / etherPrice)
    //   .toFixed(16)
    //   .toString();
    const web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    // Get the current account
    const accounts = await web3.eth.getAccounts();
    const account = accounts[0];
    let owner = account;
    let weiValue = 0;
    weiValue = Web3.utils.toWei(projectPriceEther, "ether");
    let contract = new web3.eth.Contract(ABI, contractAddress);
    console.log(contract.methods);
    const antsOwner = "0xcC20248E00FEC5B40ea878C715DF03E52738DbC9"

    contract.methods
      .donateProject(walletAddress, antsOwner)
      .send({
        from: owner,
        gas: 1500000,
        gasPrice: 0,
        value: weiValue
      })
      .then(async function (result) {
        console.log("Transaction:", result)
        const formData = {
          user_id: userData?.userId,
          project_id: projectId,
          project_donation_amount: projectPrice,
          project_donation_amount_ether: projectPriceEther,
        };
        axios.post(`${BackendURL}/donate/project`, formData)
          .then((res) => {
            console.log(res);
            getDonations(projectId)
            getDonors(projectId)
            toast.success("Donated!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          })
          .catch((error) => console.log(error));
      })
      .then(function (error) {
        console.log(error);
      })
      .catch((error) => console.log(error));

  }
  return (
    <>
      <Helmet>
        <title>{loading ? null : projects[0]?.projects_metatitle}</title>
        <meta
          name="description"
          content={loading ? null : projects[0]?.projects_metadesc}
        />
        {/* <meta
          name="keywords"
          content={JSON.parse(projects[0]?.projects_metakeywords)}
        /> */}
        <link rel="canonical" href={`${WebsiteUrl}/projects`} />
        <link
          rel="canonical"
          href={loading ? null : `${WebsiteUrl}/project/${projects[0]?.projects_slug}`}
        />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content={loading ? null : projects[0]?.projects_title} />
        <meta
          property="og:description"
          content={loading ? null : projects[0]?.projects_description}
        />
        <meta
          property="og:image"
          content={loading ? null : `${BackendURL}/nodeassets/${projects[0]?.projects_image}`}
        />
        <meta property="og:url" content={`${WebsiteUrl}/projects`} />
        <meta
          property="og:url"
          content={loading ? null : `${WebsiteUrl}/project/${projects[0]?.projects_slug}`}
        />
        <meta property="og:type" content="website" />
        {/*<meta name="twitter:card" content={projects[0].projects_title} />
        <meta name="twitter:title" content={projects[0].projects_title} />
        <meta
          name="twitter:description"
          content={projects[0].projects_metadesc}
        />*/}
        <meta
          name="twitter:image"
          content={loading ? null : `${BackendURL}/nodeassets/${projects[0]?.projects_image}`}
        />
      </Helmet>
      {loading ? null : (
        <section>
          <div
            className="page-header bg--cover"
            style={{ background: `url(${planbg})` }}
          >
            <div className="container">
              <div className="row gy-5">
                <div className="col-lg-7 col-md-6 col-sm-12 col-12 d-flex align-items-center">
                  <div className="page-header__content project-detail-text text-center text-lg-start">
                    <h2 className="text-uppercase">
                      {projects[0]?.projects_title}
                    </h2>
                    <p className="text-white">{projects[0]?.projects_text}</p>
                    {!voted ? (
                      <button
                        className="default-btn default-btn--small btn mt-3"
                        style={{
                          background: "#2caaf7",
                        }}
                        onClick={() =>
                          handleVotes(
                            projects[0]?.projects_id,
                            projects[0]?.projects_category_id
                          )
                        }
                      >
                        Vote Now
                      </button>
                    ) : (
                      <button
                        className="default-btn default-btn--small btn mt-3"
                        style={{
                          background: "#2caaf7",
                        }}
                        disabled
                      >
                        Voted
                      </button>
                    )}
                    <button
                      className="default-btn default-btn--small btn mt-3 ms-2"
                      style={{ background: 'transparent', padding: "0.1rem 0.7rem", border: '1px solid #0099FF', color: "#0099FF" }}
                      onClick={(e) => {
                        donateProject(projects[0].project_walletAddress, projects[0].projects_price, projects[0].projects_price_ether, projects[0].projects_id)
                      }}
                    >
                      Donate
                    </button>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6 col-sm-12 col-12">
                  <div className="d-flex align-items-center project-detail-img">
                    <img
                      src={`${BackendURL}/nodeassets/${projects[0]?.projects_image}`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="project_detail_donations_wrapper d-grid align-items-start mt-1">
                <div className="row project_detail_donations">
                  <div className="col-lg-3 col-md-4 col-sm-6 col-6" key={0}>
                    <div className="project_detail_donations_item">
                      <h6 className="m-0">
                        Total Raised
                      </h6>
                      
                      <h3 className="theme-color m-0">{projects[0]?.projects_total_raised} USD</h3>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 col-6" key={1}>
                    <div className="project_detail_donations_item">
                      <h6 className="m-0">
                        Donors
                      </h6>
                      <h3 className="theme-color m-0">{projects[0]?.projects_donors}</h3>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 col-6" key={2}>
                    <div className="project_detail_donations_item">
                      <h6 className="m-0">
                        End Benificeries
                      </h6>
                      <h3 className="theme-color m-0">{projects[0]?.projects_end_benificeries}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-5 container">
            <div
              className="text-white"
              dangerouslySetInnerHTML={{
                __html: projects[0]?.projects_description,
              }}
            ></div>
          </div>
        </section>
      )}
    </>
  );
};

export default ProjectDetail;
