import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { planbg } from "../utils/assets";
import axios from "axios";
import { BackendURL } from "../utils/utils";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { useStateContext } from "../context/AppContext";
import "../styles/Profile.css";

const EidProfile = () => {
  const { id } = useParams();
  const [username, setUserName] = useState("");
  const [fullname, setFullName] = useState("");
  const [useremail, setUserEmail] = useState("");
  const [profileimg, setProfileImg] = useState(null);
  const [sociallink, setSocialLink] = useState("");
  const { currentAccount, setCurrentAccount, setUserData, userData } =
    useStateContext();
  const navigate = useNavigate();

  const handleChangeStatus = ({ meta, file }, status) => {
    setProfileImg(file);
  };

  const updateProfile = (e) => {
    e.preventDefault();

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(useremail)) {
      alert("Please enter a valid email address.");
      return;
    }

    const formData = new FormData();
    formData.append("user_username", username);
    formData.append("user_fullname", fullname);
    formData.append("user_email", useremail);
    formData.append("user_sociallink", sociallink);
    formData.append("user_profileimg", profileimg);

    axios
      .put(`${BackendURL}/antsusereditprofile/${id}`, formData)
      .then((res) => {
        // console.log(res.data);
        const user = {
          userId: res?.data?.user_id,
          displayname: res?.data?.user_fullname,
          useremail: res?.data?.user_email,
          username: res?.data?.user_username,
          profileImg: res?.data?.user_profileimg,
          usersociallinks: res?.data?.user_sociallink,
        };
        setUserData(user);
        setCurrentAccount(res?.data?.user_walletaddress);
        window.localStorage.setItem("user", JSON.stringify(user));
        navigate("/profile");
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (userData) {
      setUserName(userData?.username);
      setFullName(userData?.displayname);
      setUserEmail(userData?.useremail);
      setSocialLink(userData?.usersociallinks);
    }
  }, [userData]);
  return (
    <>
      <section>
        <div
          className="page-header bg--cover"
          style={{ background: `url(${planbg})` }}
        >
          <div className="container">
            <div className="page-header__content text-center">
              <h2 className="text-uppercase">Profile Setting</h2>
            </div>
          </div>
        </div>
        <div className="section__padding">
          <div className="container">
            <form method="POST" className="row edit__form__row">
              <div className="col-lg-7 col-md-12 col-sm-12 col-12">
                <div className="d-grid align-items-center gap-3">
                  <div className="d-flex align-items-center">
                    <input
                      placeholder="Enter Username"
                      className="form-control input-theme bg-black shadow-sm round-theme"
                      value={username}
                      type="text"
                      onChange={(e) => setUserName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      placeholder="Enter Full Name"
                      className="form-control input-theme bg-black shadow-sm round-theme"
                      value={fullname}
                      type="text"
                      onChange={(e) => setFullName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      placeholder="Enter Email"
                      className="form-control input-theme bg-black shadow-sm round-theme"
                      value={useremail}
                      type="email"
                      onChange={(e) => setUserEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      placeholder="Enter Personal Website or Blog / Social"
                      value={sociallink}
                      type="text"
                      onChange={(e) => setSocialLink(e.target.value)}
                      className="form-control input-theme bg-black shadow-sm round-theme"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <Dropzone
                      onChangeStatus={handleChangeStatus}
                      inputContent="Upload Profile Image"
                      multiple={false}
                      canCancel={true}
                      canRemove={true}
                      maxFiles={1}
                      styles={{
                        dropzone: {
                          width: "100%",
                          overflow: "hidden",
                          minHeight: 50,
                          border: "1px dashed #0993e7",
                          background: "#000",
                        },
                        dropzoneActive: { borderColor: "#0993e7" },
                        inputLabel: {
                          color: "#0993e7",
                        },
                        preview: {
                          height: 50,
                          borderBottom: "0px",
                        },
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center flex-column">
                    <button
                      type="button"
                      className="default-btn default-btn--secondary default-btn--small btn round-theme w-100"
                      onClick={updateProfile}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                <div className="profile__edit__wrapper">
                  <h6 className="subtitle">Profile Image</h6>
                  <div className="d-flex align-items-center">
                    {profileimg ? (
                      <img
                        src={URL.createObjectURL(profileimg)}
                        alt="Selected file"
                      />
                    ) : (
                      <img
                        src={
                          userData?.profileImg
                            ? `${BackendURL}/nodeassets/${userData?.profileImg}`
                            : null
                        }
                        alt="user/img"
                      />
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default EidProfile;
