import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import AllProjects from "./pages/AllProjects";
import ProjectDetail from "./pages/ProjectDetail";
import Signup from "./pages/Signup";
import { useStateContext } from "./context/AppContext";
import Profile from "./pages/Profile";
import EidProfile from "./pages/EidProfile";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BackendURL } from "./utils/utils";
import Success from "./pages/Success";
import Knowledge from "./pages/Knowledge";
import Blogs from "./pages/Blogs";
import BlogDetail from "./pages/BlogDetail";
import Commerce from "./pages/Commerce";
import Category from "./pages/Category";
import ProductDescription from "./pages/ProductDescription";

const App = () => {
  const { setUserData, userData, currentAccount, setButtonBgColor,sethandleClick,sethandleClick2 } =
    useStateContext();

    const handleNav = ()=>{
      sethandleClick(false)
      sethandleClick2(false)
    }

  useEffect(() => {
    const user = JSON.parse(localStorage?.getItem("user"));
    if (user) {
      setUserData(user);
    }
  }, []);

  useEffect(() => {
    axios
      .get(`${BackendURL}/buttonbgcolor`)
      .then((res) => setButtonBgColor(res.data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div onClick={handleNav}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/knowledge" element={<Knowledge />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/:slug" element={<BlogDetail />} />
        <Route path="/projects" element={<AllProjects />} />
        <Route path="/project/:slug" element={<ProjectDetail />} />
        <Route path="/commerce" element={<Commerce />} />
        <Route path="/category/:slug" element={<Category />} />
        <Route path="/product/:slug" element={<ProductDescription />} />
        {userData && (
          <>
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/:id" element={<EidProfile />} />
            <Route path="/success" element={<Success />} />
          </>
        )}
        <Route path="/signup" element={<Signup />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </div>
  );
};

export default App;
