import React, { useEffect, useState } from "react";
import { teambg, userimg } from "../utils/assets";
import { Link } from "react-router-dom";
import axios from "axios";
import { BackendURL } from "../utils/utils";

const Team = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(`${BackendURL}/meetteam`)
      .then((res) => setData(res?.data))
      .catch((err) => console.log(err));

    // console.log(data, 'data')
  }, []);
  return (
    <>
      {data?.length > 0 ? (
        <section
          className="team padding-top"
          id="team"
          style={{ background: `${teambg}` }}
        >
          <div className="container">
            <div className="section-header text-center">
              <p className="subtitle">Team Member</p>
              <h2>Meet The Team</h2>
            </div>
            <div className="team__wrapper">
              <div className="row g-4">
                {data?.map((d, i) => (
                  <div className="col-lg-6" key={i}>
                    <div className="team__item">
                      <div className="team__item-inner">
                        <div className="team__item-thumb round-theme overflow-hidden">
                          <img
                            src={`${BackendURL}/nodeassets/${d.team_image}`}
                            alt="user/img"
                            className="round-theme"
                          />
                        </div>
                        <div className="team__item-content">
                          <div className="team__item-author">
                            <h4>
                              <Link to={`#`}>{d.team_name}</Link>{" "}
                            </h4>
                            <p>{d.team_position}</p>
                          </div>
                          <p>
                            {d.team_text}
                          </p>
                          <ul className="social">
                            <li>
                              <Link to={`/${d.team_twitter}`} className="social__link">
                                <i className="fa-brands fa-twitter"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to={`/${d.team_facebook}`} className="social__link">
                                <i className="fa-brands fa-facebook-f"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to={`/${d.team_linkedin}`} className="social__link">
                                <i className="fa-brands fa-linkedin-in"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to={`/${d.team_instagram}`} className="social__link">
                                <i className="fa-brands fa-instagram"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default Team;
