import React, { useEffect, useState } from "react";
import axios from "axios";
import { BackendURL } from "../utils/utils";

const OurMission = () => {
  const [title, setTitle] = useState([]);
  const [sectionData, setSectionData] = useState([]);

  useEffect(() => {
    Promise.all([
      axios.get(`${BackendURL}/missiontitle`),
      axios.get(`${BackendURL}/missionsection`),
    ])
      .then((res) => {
        setTitle(res[0].data);
        setSectionData(res[1].data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      {sectionData?.length > 0 ? (
        <section className="about padding-top" id="aboutsection">
          <div className="container">
            <div className="d-flex flex-column align-items-center justify-content-center text-center mb-5">
              <p className="subtitle">{title[0]?.mission_tag}</p>
              <h2 className="text-white">{title[0]?.mission_title}</h2>
            </div>
            <div className="about__wrapper">
              <div className="row g-5">
                {sectionData?.map((d, i) => (
                  <div key={i} className="col-lg-4 col-md-6 col-12 col-12">
                    <div
                      className="about__content"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <ul className="d-grid align-items-center">
                        <h5 className="text-white fw-semibold theme-color">{d.mission_title}</h5>
                        <div dangerouslySetInnerHTML={{__html: d.mission_text}}></div>
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default OurMission;
