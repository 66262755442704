import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { BackendURL,WebsiteUrl } from '../utils/utils';
import { truncate } from "lodash";
import { Helmet } from "react-helmet";
export default function Knowledge() {
  const [data, setdata] = useState([])
  const [data2, setdata2] = useState([])

  useEffect(() => {
    axios
      .get(`${BackendURL}/knowledge/get`)
      .then((res) => setdata(res.data))
      .catch((error) => console.log(error));

    axios.get(`${BackendURL}/homeKnowledgeSco/get`)
      .then((response) => {
        setdata2(response.data)
      }).catch((err) => {
        console.log(err)
      })
  }, []);
  return (
    <>
      {
        data2[0] && (
          <Helmet>
            <title>{data2[0]?.meta_title}</title>

            <meta
              name="description"
              content={data2[0]?.meta_description}
            />
            <meta
              name="keywords"
              content={JSON.parse(data2[0]?.meta_keywords)}
            />
            <link rel="canonical" href={`${WebsiteUrl}`} />
            <link
              rel="canonical"
              href={`${WebsiteUrl}`}
            />
            <meta name="robots" content="index,follow" />
            <meta property="og:title" content={data2[0]?.meta_title} />
            <meta
              property="og:description"
              content={data2[0]?.meta_description}
            />
            <meta
              property="og:image"
              content={`${BackendURL}/nodeassets/${data2[0]?.meta_img}`}
            />
            <meta property="og:url" content={`${WebsiteUrl}`} />
            <meta
              property="og:url"
              content={`${WebsiteUrl}`}
            />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content={data2[0]?.meta_title} />
            <meta name="twitter:title" content={data2[0]?.meta_title} />
            <meta
              name="twitter:description"
              content={data2[0]?.meta_description}
            />

            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="favicon" href="/favicon.ico" />
            <meta
              name="twitter:image"
              content={`${BackendURL}/nodeassets/${data2[0]?.meta_img}`}
            />
          </Helmet>
        )
      }
      <section
        // key={i}
        className="collection padding-top"
        id="projects"
      >
        <div className="container">
          <div className="collection__wrapper">
            <div className="row g-4">
              {data?.map((t) => (
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <div className='card border-0 shadow-lg border-white' style={{ backgroundColor: '#000000' }}>
                    <iframe src={t.VideoLink} height="200" title="Iframe Example"></iframe>
                    <p style={{ fontSize: '1.1rem' }} className='p-3 pb-0 fw-bold'>{truncate(t.title, { length: 60 })}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
