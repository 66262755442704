import React, { useEffect, useState } from "react";
import axios from "axios";
import { BackendURL } from "../utils/utils";
import { herobg, staricon } from "../utils/assets";
import { Link } from "react-router-dom";
import { useStateContext } from "../context/AppContext";
const Hero = () => {
  const [data, setData] = useState([]);
  const [data2, setdata2] = useState([])
  const [whitePaper, setwhitePaper] = useState("")
  // console.log(data2,'checker')
  const { buttonBgColor } = useStateContext();

  useEffect(() => {
    axios
      .get(`${BackendURL}/herosection`)
      .then((res) => {
        setData(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${BackendURL}/howtovote/get`)
      .then((res) => {
        setdata2(res?.data[0].htv_VideoLink);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${BackendURL}/whitePaper/get`)
      .then((res) => {
        // setdata2(res?.data[0].htv_VideoLink);
        setwhitePaper(res?.data[0].wp_pdf)
      })
      .catch((err) => console.log(err));

  }, []);

  const handleDownload = () => {
    const fileUrl = `${BackendURL}/nodeassets/${whitePaper}`;
    window.open(fileUrl, '_blank');
    // const link = document.createElement('a');
    // link.href = fileUrl;
    // link.setAttribute('download', 'WhitePaper.pdf');

    // document.body.appendChild(link);

    // link.click();

    // document.body.removeChild(link);
  };

  return (
    <>
      {!data?.length > 0 ? null : (
        <section
          id="hero"
          className="banner hero__section"
          style={{ background: `url(${herobg})` }}
        >
          <div className="container">
            <div className="banner__wrapper">
              <div className="row g-5 align-items-center">
                <div className="col-lg-6">
                  <div
                    className="banner__content"
                    data-aos="fade-right"
                    data-aos-duration="2000"
                  >
                    <h3 className="theme-color">
                      {data[0]?.hero_subtitle}
                      <span className="color--secondary-color">
                        <i className="ms-2 fa-solid fa-wifi"></i>
                      </span>
                    </h3>
                    <h1>{data[0]?.hero_title}</h1>
                    <p>{data[0]?.hero_text}</p>
                    <div className="btn-group">
                      <Link
                        to={`/${data[0]?.hero_btnUrl}`}
                        className="default-btn default-btn--secondary round-theme"
                        style={{
                          background: `${buttonBgColor[0]?.button_bgcolor}`,
                        }}
                      >
                        {data[0]?.hero_btnText}
                      </Link>
                      <div
                        onClick={handleDownload}
                        className="p-1 text-center"
                        style={{ border: '3px solid #004BFF', borderRadius: 30, width: '180px', fontWeight: 'bold', cursor: 'pointer' }}
                      >
                        Download <br /> White Paper
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="banner__thumb d-flex justify-content-center"
                    data-aos="fade-left"
                    data-aos-duration="2000"
                  >
                    <img
                      src={`${BackendURL}/nodeassets/${data[0]?.hero_image}`}
                      alt="banner"
                    />
                    <div
                      className="banner__video"
                      // href="https://www.youtube.com/embed/usekyJo9Cdo"
                      // data-rel="lightcase"
                      data-bs-toggle="modal" data-bs-target="#exampleModal"
                    >
                      <div className="banner__video-inner" >
                        <svg
                          viewBox="0 0 100 100"
                          style={{
                            background: `${buttonBgColor[0]?.button_bgcolor}`,
                          }}
                        >
                          <defs>
                            <path
                              id="circle"
                              d="
                            M 50, 50
                            m -37, 0
                            a 37,37 0 1,1 74,0
                            a 37,37 0 1,1 -74,0"
                            />
                          </defs>
                          <text>
                            <textPath fill="#fff" xlinkHref="#circle">
                              How to Vote * How to Vote
                            </textPath>
                          </text>
                        </svg>
                        <span>
                          <i className="fa-solid text-white fs-5 fa-play"></i>
                        </span>
                      </div>
                    </div>

                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header position-absolute w-100" style={{ zIndex: 1, borderBottom: 0 }}>
                            <button type="button" class="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body p-0 bg-dark">
                            <iframe src={data2} className="w-100" height="300" title="Iframe Example"></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="banner-shape">
                      <img src={staricon} alt="shape" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Hero;
