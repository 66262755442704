import axios from "axios";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { BackendURL } from "../utils/utils";

const Stats = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(`${BackendURL}/antsstats`)
      .then((res) => {
        setData(res?.data);
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <>
      {data?.length > 0 ? (
        <section className="counter counter--uplifted">
          <div className="container">
            <div className="counter__wrapper">
              <div className="row g-1">
                {data?.map((d, i) => (
                  <div className="col-lg-4 col-sm-6" key={i}>
                    <div className="counter__item">
                      <div className="counter__item-content">
                        <h2>
                          <span className="purecounter">
                            <CountUp
                              enableScrollSpy={true}
                              start={0}
                              end={d?.states_value}
                            />
                          </span>
                        </h2>
                        <p>{d.stats_title}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default Stats;
