import { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import "../styles/StripePaymentMethod.css";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useStateContext } from "../context/AppContext";
import { toast } from "react-toastify";
import { BackendURL } from "../utils/utils";
import axios from "axios";

export default function StripePaymentModel({ id, item, setOpenStripeModal }) {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { userData, connectMetaMask } = useStateContext();

  const createSubscription = async (id, item) => {
    if (userData) {
      setLoading(true);
      const currentDate = new Date();
      const expiryDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        currentDate.getDate()
      );
      axios
        .get(`${BackendURL}/getCashbackPercentage`)
        .then(async (res) => {
          const cashbackPercentage = res.data[0].cashback_howmuch;
          const rewardAmount = (cashbackPercentage / 100.0) * item.plan_amount;

          const paymentMethod = await stripe.createPaymentMethod({
            card: elements.getElement("card"),
            type: "card",
          });
          const response = await fetch(`${BackendURL}/create-subscription`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userName,
              userEmail,
              paymentMethod: paymentMethod.paymentMethod.id,
              user_id: userData.userId,
              purchased_plan_id: id,
              purchase_date: currentDate.toISOString(),
              expiry_date: expiryDate.toISOString(),
              plan_name: item.plan_name,
              plan_membership: item.plan_membership,
              plan_amount: item.plan_amount,
              cashback_list_price: rewardAmount,
              puchase_plan_id: id,
              puchase_plan_wallet_address: "",
              cashback_list_status: "Pending",
              purchase_plan_status: "Pending",
            }),
          });
          if (!response.ok) {
            toast.error("Payment unsuccessful!");
            setLoading(false);
            return;
          }
          const data = await response.json();
          const confirm = await stripe.confirmCardPayment(data.clientSecret);
          if (confirm.error) {
            toast.error("Payment unsuccessful!");
            setLoading(false);
            return;
          }
          setLoading(false);
          setUserName("");
          setUserEmail("");
          toast.success("Payment Successful! Subscription active.");
        })
        .catch((error) => console.log(error));
    } else {
      connectMetaMask();
    }
  };
  return (
    <div className="stripepayment-overlay">
      <div className="stripepayment-modal">
        <div
          className={"stripe-close-icon"}
          onClick={() => setOpenStripeModal(false)}
        >
          <XCircleIcon width={25} height={25} fill="#000" />
        </div>
        <div className="d-grid align-items-center gap-3">
          <div className="d-flex align-items-center">
            <input
              placeholder="Enter Username"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              type="text"
              required
              className="form-control stripe-input-theme bg-black shadow-sm round-theme"
            />
          </div>
          <div className="d-flex align-items-center">
            <input
              placeholder="Enter Email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              type="email"
              required
              className="form-control stripe-input-theme bg-black shadow-sm round-theme"
            />
          </div>
          <CardElement
            options={{
              style: {
                base: {
                  color: "#ffff",
                },
              },
            }}
            className="form-control stripe-input-theme bg-black shadow-sm round-theme"
          />
          <div className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => createSubscription(id, item)}
              className="default-btn default-btn--small mt-3 btn w-100"
            >
              {loading ? "Getting Subscribe ..." : "Subscribe"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
