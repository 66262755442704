import React, { useEffect, useState } from "react";
import axios from "axios";
import { BackendURL } from "../utils/utils";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "../styles/ShowUsers.css";

const OurUsers = () => {
  const [data, setData] = useState([]);
  const [planType, setPlanType] = useState([]);

  useEffect(() => {
    Promise.all([
      axios.get(`${BackendURL}/showpremiumusers`),
      axios.get(`${BackendURL}/createplans`),
    ])
      .then((res) => {
        setData(res[0].data);
        setPlanType(res[1].data);
      })
      .catch((err) => console.log(err));
  }, []);
  //   console.log(data)

  if (!data.length) {
    return null; // Return null when there is no data available
  }

  const breakPointOptions = {
    320: {
      slidesPerView: 1,
    },
    550: {
      slidesPerView: 1,
    },
    767: {
      slidesPerView: 2,
    },
    991: {
      slidesPerView: 2.5,
    },
    1200: {
      slidesPerView: 3,
    },
  };

  return (
    <>
      {planType?.length > 0
        ? planType.map((t) => {
            const filteredUsers = data.filter(
              (x) => x.plan_membership === t.plan_membership
            );
            return (
              <section
                key={t.plan_membership}
                className="team padding-top"
                id="team"
              >
                <div className="container">
                  <div className="section-header text-center">
                    <p className="subtitle">Our Users</p>
                    <h3>{t.plan_membership}</h3>
                  </div>
                  <Swiper
                    loop={true}
                    slidesPerView={3}
                    spaceBetween={30}
                    grabCursor={true}
                    speed={5000}
                    breakpoints={breakPointOptions}
                  >
                    {filteredUsers.map((user, i) => (
                      <SwiperSlide key={i}>
                        <div className="user__item">
                          <div className="user__item__img">
                            <img
                              src={`${BackendURL}/nodeassets/${user?.user_profileimg}`}
                              alt="user/img"
                              className="round-theme"
                            />
                          </div>
                          <div className="user__item__text">
                            <h4>
                              <Link to={`#`}>{user?.user_fullname}</Link>{" "}
                            </h4>
                            <p>@{user?.user_username}</p>
                            <Link
                              to={`/${user?.user_sociallink}`}
                              className="d-flex align-items-center gap-2"
                            >
                              <i className="fa fa-arrow-right-long"></i>
                              <i className="fa fa-link"></i>
                              {/* <span className="">{user?.user_sociallink}</span> */}
                            </Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </section>
            );
          })
        : null}
    </>
  );
};

export default OurUsers;
