import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BackendURL } from "../utils/utils";

const AntsWallet = () => {
  const cardRef = useRef(null);

  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(`${BackendURL}/antswallet`)
      .then((res) => setData(res?.data))
      .catch((err) => console.log(err));

    // console.log(data, 'data')
  }, []);

  useEffect(() => {
    const cards = document.querySelectorAll(".pricings-card");
    let maxHeight = 0;
    cards.forEach((card) => {
      maxHeight = Math.max(maxHeight, card.offsetHeight);
    });
    cards.forEach((card) => {
      card.style.height = `${maxHeight}px`;
    });
  }, []);

  return (
    <>
      {data?.length > 0 ? (
        <section className="blog padding-top" id="antswallet">
          <div className="container">
            <div className="section-header text-center">
              <p className="subtitle">Our Wallet</p>
              <h2>ANTS wallet</h2>
            </div>
            <div className="blog__wrapper">
              <div className="row pricings-row">
                {data?.map((p, i) => (
                  <div
                    className="col-lg-6"
                    key={i}
                    data-aos="fade-up"
                    data-aos-duration="300"
                  >
                    <div
                      className="d-flex flex-column px-4 py-4 justify-content-between h-100 pricings-card round-theme"
                      ref={cardRef}
                      style={{ background: "#000" }}
                    >
                      <div className="d-grid align-items-center">
                        <div className="d-flex align-items-start flex-column">
                          <h4 className="text-white">
                            <Link to={`#`} style={{ color: "#2caaf7" }}>
                              {p.wallet_title}
                            </Link>
                          </h4>
                        </div>
                        <div className="p-0 mt-3 d-grid align-items-center gap-3">
                          <div
                            dangerouslySetInnerHTML={{ __html: p.wallet_text }}
                            className="text-white"
                          ></div>
                        </div>
                      </div>
                      {/* <div className="pt-3 d-flex align-items-center justify-content-center mt-4">
                        <button
                          className="btn px-3 text-white py-2 text-black fw-bold btn-sm round-theme"
                          data-bs-toggle="modal"
                          data-bs-target="#wallet-option"
                          style={{
                            background: "#2caaf7",
                          }}
                        >
                          Connect Wallet
                        </button>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default AntsWallet;
