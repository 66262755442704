import React, { useEffect, useState } from 'react'
import '../styles/BlogDetail.css'
import axios from 'axios'
import { BackendURL, WebsiteUrl } from '../utils/utils'
import { useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
export default function BlogDetail() {
    const { slug } = useParams();
    const [data, setdata] = useState([])
    useEffect(() => {
        axios.get(`${BackendURL}/blog/get`)
            .then((response) => {
                const filterData = response.data.filter((t) => t.blog_slug == slug)
                setdata(filterData)
            }).catch((err) => {
                console.log(err)
            })
    }, [])
    return (
        <>
            {
                data[0] && (
                    <Helmet>
                        <title>{data[0]?.blog_metatitle}</title>

                        <meta
                            name="description"
                            content={data[0]?.blog_metadesc}
                        />
                        <meta
                            name="keywords"
                            content={JSON.parse(data[0]?.blog_metakeywords)}
                        />
                        <link rel="canonical" href={`${WebsiteUrl}/blogs`} />
                        <link
                            rel="canonical"
                            href={`${WebsiteUrl}/blog/${data[0]?.blog_slug}`}
                        />
                        <meta name="robots" content="index,follow" />
                        <meta property="og:title" content={data[0]?.blog_title} />
                        <meta
                            property="og:description"
                            content={data[0]?.blog_metadesc}
                        />
                        <meta
                            property="og:image"
                            content={`${BackendURL}/nodeassets/${data[0]?.blog_img}`}
                        />
                        <meta property="og:url" content={`${WebsiteUrl}/blogs`} />
                        <meta
                            property="og:url"
                            content={`${WebsiteUrl}/blog/${data[0]?.blog_slug}`}
                        />
                        <meta property="og:type" content="website" />
                        <meta name="twitter:card" content={data[0]?.blog_title} />
                        <meta name="twitter:title" content={data[0]?.blog_title} />
                        <meta
                            name="twitter:description"
                            content={data[0]?.blog_metadesc}
                        />
                        <meta
                            name="twitter:image"
                            content={`${BackendURL}/nodeassets/${data[0]?.blog_img}`}
                        />
                    </Helmet>
                )
            }
            <section
                className="collection padding-top"
                id="projects"
            >
                <div className='container'>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-lg-8 col-md-10 col-sm-10 col-12'>
                            <div>
                                <p className="NewDetailH1">
                                    {data[0]?.blog_title}
                                </p>

                                <div className='pt-2'>
                                    <img src={`${BackendURL}/nodeassets/${data[0]?.blog_img}`} height={"100%"} width={'100%'} />
                                </div>

                                <div className="NewDetailP" dangerouslySetInnerHTML={{ __html: data[0]?.blog_desc }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
