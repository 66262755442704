import React, { useEffect, useState } from "react";
import { planbg } from "../utils/assets";
import axios from "axios";
import { BackendURL } from "../utils/utils";
import ProductsCard from "../components/ProductsCard";
import '../styles/Commerce.css'
import { useNavigate } from "react-router-dom";

const Commerce = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [category, setcategory] = useState([])
  const [subCategory, setsubCategory] = useState([])
  const [filteredSubCate, setfilteredSubCate] = useState([])
  const [sliderImages, setsliderImages] = useState([])
  const [activeHover, setactiveHover] = useState(false)

  const [selectedCateName, setselectedCateName] = useState('')


  const productsPerPage = 12; // Number of products to display per page
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

  const totalPages = Math.ceil(products.length / productsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const fetchProducts = () => {
    setLoading(true);
    axios.get(`${BackendURL}/antsproducts`)
      .then((res) => {
        setProducts(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    axios.get(`${BackendURL}/productscategory`)
      .then((res) => {
        setcategory(res.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios.get(`${BackendURL}/slider/get`)
      .then((res) => {
        setsliderImages(res.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios.get(`${BackendURL}/productsubcategory`)
      .then((res) => {
        setsubCategory(res.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleOnHover = (D) => {
    const filteredData = subCategory.filter((t) => t.product_category_id == D.product_category_id)
    setselectedCateName(D.product_category_name)
    setfilteredSubCate(filteredData)
    setactiveHover(true)
  }

  const handleSubCate = (name) => {
    navigate(`/category/${selectedCateName.replace(/\s+/g, "-")}?${'subcategory=' + name.replace(/\s+/g, "-").replace(/&/g, "and")}`)
  }


  return (
    <>
      <section>
        <div className="container" style={{ padding: "2rem 0" }}>
          <div className="row">
            <div className="col-lg-3 p-0 pt-1">
              {
                category?.map((t, i) => (
                  <div key={i} onMouseEnter={() => handleOnHover(t)} onMouseLeave={() => setactiveHover(false)} onClick={() => navigate(`/category/${t.product_category_name.replace(/\s+/g, "-")}`)} className="border text-center category" style={{ padding: "8.9px" }}>{t.product_category_name}</div>
                ))
              }
            </div>
            <div className="col-lg-9 p-0 pt-1">
              {
                activeHover ?
                  <div className="h-100 w-100 bg-white" onMouseEnter={() => setactiveHover(true)} onMouseLeave={() => setactiveHover(false)}>
                    <div className="row" style={{ padding: "10px 30px 10px 30px" }}>
                      {
                        filteredSubCate.length !== 0 ? (
                          filteredSubCate?.map((c, i) => (
                            <div className="col-4 text-dark fw-bold pt-3 subcategory" onClick={() => handleSubCate(c.product_subcategory_name)}>{c.product_subcategory_name}</div>
                          ))
                        )
                          :
                          <div className="col-4 text-dark fw-bold pt-3">Not Found</div>
                      }
                    </div>
                  </div>
                  :
                  <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                      {
                        sliderImages?.map((t, i) => (
                          <div className={`carousel-item ${i == 0 && 'active'}`} key={i}>
                            <img src={`${BackendURL}/nodeassets/${t.slider_image}`} className="d-block w-100" style={{ height: "380px" }} alt="..." />
                          </div>
                        ))
                      }
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
              }
            </div>
          </div>
        </div>
        <div className="container" style={{ padding: "5rem 0" }}>
          <div className="row gy-5">
            {!loading &&
              currentProducts.map((p, i) => (
                <div key={i} className="col-lg-4 col-md-6 col-12 col-12">
                  <ProductsCard
                    title={p?.products_title}
                    text={p?.products_text}
                    url={p?.products_affiliateurl}
                    slug={p?.products_slug}
                    featuredImg={p?.products_featuredImg}
                  />
                </div>
              ))}
          </div>
          <div className="pagination mt-5 d-flex justify-content-center">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`${currentPage === index + 1 ? 'active' : ''} btn pagination-btn`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Commerce;