import React, { useEffect, useState } from "react";
import axios from "axios";
import { planbg } from "../utils/assets";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../context/AppContext";
import { BackendURL } from "../utils/utils";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { ColorRing } from "react-loader-spinner";

const Signup = () => {
  const [username, setUserName] = useState("");
  const [fullname, setFullName] = useState("");
  const [useremail, setUserEmail] = useState("");
  const [userpassword, setUserPassword] = useState("");
  const [profileimg, setProfileImg] = useState("");
  const [sociallink, setSocialLink] = useState("");
  const { currentAccount } = useStateContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChangeStatus = ({ meta, file }, status) => {
    setProfileImg(file);
  };

  const handleSignup = (e) => {
    e.preventDefault();
    // Check if username is empty
    if (!username) {
      alert("Please enter a username.");
      return;
    }

    // Check if email is empty
    if (!useremail) {
      alert("Please enter an email address.");
      return;
    }

    // Check if password is empty
    if (!userpassword) {
      alert("Please enter a password.");
      return;
    }

    // Check if currentAccount is empty
    if (!currentAccount) {
      alert("Please provide a current account.");
      return;
    }

    // Check if sociallink is empty
    if (!sociallink) {
      alert("Please provide a social link.");
      return;
    }

    // Check if profileimg is empty
    if (!profileimg) {
      alert("Please upload a profile image.");
      return;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(useremail)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Validate password strength
    // Add your password validation logic here

    // All validations passed, proceed with form submission

    const formData = new FormData();
    formData.append("user_username", username);
    formData.append("user_fullname", fullname);
    formData.append("user_email", useremail);
    formData.append("user_password", userpassword);
    formData.append("user_walletaddress", currentAccount);
    formData.append("user_sociallink", sociallink);
    formData.append("user_profileimg", profileimg);

    setLoading(true);
    axios
      .post(`${BackendURL}/antsusersignup`, formData)
      .then((res) => {
        navigate("/signin");
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section>
        <div
          className="page-header bg--cover"
          style={{ background: `url(${planbg})` }}
        >
          <div className="container">
            <div className="page-header__content text-center">
              <h2 className="text-uppercase">Sign Up</h2>
              <p className="text-white">
                Join forces to achieve goals and support important charitable
                causes.
              </p>
            </div>
          </div>
        </div>
        <div style={{ padding: "5rem 0" }}>
          <div className="container">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 mx-auto">
              <form method="POST" className="d-grid align-items-center gap-4">
                <div className="d-flex align-items-center">
                  <input
                    placeholder="Enter Username"
                    className="form-control input-theme bg-black shadow-sm round-theme"
                    value={username}
                    type="text"
                    onChange={(e) => setUserName(e.target.value)}
                    required
                  />
                </div>
                <div className="d-flex align-items-center">
                  <input
                    placeholder="Enter Full Name"
                    className="form-control input-theme bg-black shadow-sm round-theme"
                    value={fullname}
                    type="text"
                    onChange={(e) => setFullName(e.target.value)}
                    required
                  />
                </div>
                <div className="d-flex align-items-center">
                  <input
                    placeholder="Enter Email"
                    className="form-control input-theme bg-black shadow-sm round-theme"
                    value={useremail}
                    type="email"
                    onChange={(e) => setUserEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="d-flex align-items-center">
                  <input
                    placeholder="Enter Password"
                    className="form-control input-theme bg-black shadow-sm round-theme"
                    value={userpassword}
                    type="password"
                    onChange={(e) => setUserPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="d-flex align-items-center">
                  <input
                    placeholder="Enter Wallet Address"
                    value={
                      currentAccount ? currentAccount : "Wallet Not Connected"
                    }
                    readOnly
                    type="text"
                    className="form-control input-theme bg-black shadow-sm round-theme"
                  />
                </div>
                <div className="d-flex align-items-center">
                  <input
                    placeholder="Enter Personal Website or Blog / Social"
                    value={sociallink}
                    type="text"
                    onChange={(e) => setSocialLink(e.target.value)}
                    className="form-control input-theme bg-black shadow-sm round-theme"
                  />
                </div>
                <div className="d-flex align-items-center">
                  <Dropzone
                    onChangeStatus={handleChangeStatus}
                    inputContent="Upload Profile Image"
                    multiple={false}
                    canCancel={true}
                    canRemove={true}
                    maxFiles={1}
                    styles={{
                      dropzone: {
                        width: "100%",
                        overflow: "hidden",
                        minHeight: 50,
                        border: "1px dashed #0993e7",
                        background: "#000",
                      },
                      dropzoneActive: { borderColor: "#0993e7" },
                      inputLabel: {
                        color: "#0993e7",
                      },
                      preview: {
                        height: 50,
                        borderBottom: "0px",
                      },
                    }}
                  />
                </div>
                <div className="d-flex align-items-center flex-column">
                  <button
                    type="button"
                    className="default-btn default-btn--secondary default-btn--small btn round-theme w-100"
                    onClick={handleSignup}
                  >
                    Sign Up Now{" "}
                    {loading ? (
                      <ColorRing
                        visible={true}
                        height="25"
                        width="25"
                        ariaLabel="blocks-loading"
                        wrapperClass="blocks-wrapper"
                        colors={[
                          "#fff",
                          "#fff",
                          "#fff",
                          "#fff",
                          "#fff",
                          "#fff",
                        ]}
                      />
                    ) : null}
                  </button>
                  {/* <p className="text-white mt-3">
                    Already Have An Account?{" "}
                    <Link to={`/signin`} className="subtitle btn-link">
                      Sign In
                    </Link>
                  </p> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signup;
