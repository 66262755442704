import React from "react";
import VoteCard from "../VoteCard/VoteCard";

const VottedProjects = ({ projects }) => {
  return (
    <>
      {!projects?.length > 0 ? (
        <p className="fs-4 fw-bold m-0" style={{color: "#94a3b8"}}>No Votted Projects</p>
      ) : (
        <div className="row gy-5">
          {projects?.map((p, i) => (
            <div key={i} className="col-lg-4 col-md-6 col-12 col-12">
              <VoteCard
                id={p?.projects_id}
                text={p?.projects_text}
                title={p?.projects_title}
                image={p?.projects_image}
                price={p?.projects_price}
                slug={p?.projects_slug}
                item={p}
                isVoted={true}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default VottedProjects;
