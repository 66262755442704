
export const BackendURL = "https://antsprotocolapi.unialsolutions.com";
export const WebsiteUrl = "https://antsprotocol.unialsolutions.com";
// export const BackendURL = "http://localhost:5000";
export const contractAddress = "0xD3fEf5a5A911092050d7198852cE209326DDE6EB";
export const pricings = [
  {
    tag: "Plan 1",
    title: "Worker Ant",
    list: [
      {
        text: "Our worker ant plan lets you support charity projects and receive 8% cashback on your daily purchases with your visa card.",
      },
      {
        text: "A percentage of the cashback goes directly to the association",
      },
      { text: "Any other visa card adedd is 0.99$ for card" },
    ],
  },
  {
    tag: "Plan 2",
    title: "Queen Ant",
    list: [
      {
        text: "$4.99/month and offers 15% cashback on your daily purchases with your visa card",
      },
      {
        text: "You can sign with illimitated visa cards to receive cash back",
      },
      {
        text: "A small percentage of the cashback goes directly to the association",
      },
      {
        text: "Access to exclusive airdrop, events and surprises.",
      },
    ],
  },
];
