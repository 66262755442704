import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import { BackendURL } from "../utils/utils";
import VoteCard from "./VoteCard/VoteCard";
import { useStateContext } from "../context/AppContext";

const Projects = () => {
  const { userData, buttonBgColor } = useStateContext();
  const [projects, setProjects] = useState([]);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    const request1 = axios.get(`${BackendURL}/projectcategory`);
    const request2 = axios.get(`${BackendURL}/vote/project_all`);

    Promise.all([request1, request2])
      .then(async (responses) => {
        const catg = responses[0].data;
        const project = responses[1].data;
        console.log("project", project)
        let filteredData = project;
        if (userData) {
          filteredData = await Promise.all(project.map(async (element) => {
            const isUserVotedList = element.votes.filter((item) => item.user_id == userData?.userId)
            let isVoted = false
            if (isUserVotedList.length > 0) {
              isVoted = true
            }
            return {
              ...element,
              isVoted: isVoted
            }
          }));
        }
        const filterProjects = {};

        catg.forEach((category) => {
          filterProjects[category.category_id] = filteredData?.filter(
            (project) => project?.projects_category_id === category.category_id
          );
        });
        console.log("filteredData", filterProjects)
        setCategory(catg);
        setProjects(filterProjects);
      })
      .catch((error) => {
        console.error(error);
      });

  }, []);

  // console.log(projects);

  const breakPointOptions = {
    320: {
      slidesPerView: 1,
    },
    550: {
      slidesPerView: 1,
    },
    767: {
      slidesPerView: 2,
    },
    991: {
      slidesPerView: 2.5,
    },
    1200: {
      slidesPerView: 3,
    },
  };

  return (
    <>
      {!projects?.length > 0 ? (
        category?.map((cat, i) => (
          <section
            key={i}
            className="collection padding-top"
            id="projects"
          >
            <div className="container">
              <div className="collection__wrapper">
                <div className="row g-4">
                  <div className="col-lg-3">
                    <div className="collection__header">
                      <div className="collection__header-content">
                        <p className="subtitle">Collection</p>
                        <h3>Projects for {cat.category_name}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <Swiper
                      loop={true}
                      autoplay={{
                        disableOnInteraction: true,
                        delay: 1,
                        reverseDirection: true,
                      }}
                      modules={[Autoplay]}
                      speed={3000}
                      slidesPerView={3}
                      spaceBetween={10}
                      breakpoints={breakPointOptions}
                    >
                      {projects[cat?.category_id]?.slice(0, 3)?.map((p, i) => (
                        <SwiperSlide className="swiper-slide" key={i}>
                          <VoteCard
                            id={p?.projects_id}
                            text={p?.projects_text}
                            title={p?.projects_title}
                            image={p?.projects_image}
                            price={p?.projects_price}
                            slug={p?.projects_slug}
                            isTruncate
                            item={p}
                            isVoted={p?.isVoted}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <div className="col-lg-9">
                    <Swiper
                      loop={true}
                      autoplay={{
                        disableOnInteraction: true,
                        delay: 1,
                        reverseDirection: true,
                      }}
                      modules={[Autoplay]}
                      speed={3000}
                      slidesPerView={3}
                      spaceBetween={10}
                      breakpoints={breakPointOptions}
                    >
                      {projects[cat?.category_id]?.slice(3)?.map((p, i) => (
                        <SwiperSlide className="swiper-slide" key={i}>
                          <VoteCard
                            id={p?.projects_id}
                            text={p?.projects_text}
                            title={p?.projects_title}
                            image={p?.projects_image}
                            price={p?.projects_price}
                            slug={p?.projects_slug}
                            isTruncate
                            item={p}
                            isVoted={p?.isVoted}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <div className="col-lg-3 d-flex align-items-center justify-content-center">
                    <div className="collection__btn see_more_btn">
                      <Link
                        to={`/projects`}
                        className="default-btn default-btn--secondary"
                        style={{
                          background: `${buttonBgColor[0]?.button_bgcolor}`
                        }}
                      >
                        <span>See More</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))
      ) : (
        <div>Loadding ....</div>
      )}
    </>
  );
};

export default Projects;
