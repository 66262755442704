import { createContext, useContext, useState } from "react";
import { BackendURL } from "../utils/utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const TheContext = createContext();

const { ethereum } = window;

export default function AppContext({ children }) {
  const [currentAccount, setCurrentAccount] = useState("");
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [walletConnecting, setWalletConnecting] = useState(false);
  const [buttonBgColor, setButtonBgColor] = useState([]);
  const [handleClick, sethandleClick] = useState(false)
  const [handleClick2, sethandleClick2] = useState(false)
  const [scrollName, setscrollName] = useState("")
  const checkIfWalletIsConnected = async () => {
    try {
      //   console.log(ethereum); // Check if ethereum object is defined
      if (!ethereum) {
        alert("Please install MetaMask.");
        return;
      }

      const accounts = await ethereum.request({ method: "eth_accounts" });
      // console.log(accounts, 'effect');
      setCurrentAccount(accounts[0]);

      // Rest of the code...
    } catch (error) {
      console.log(error);
    }
  };

  const connectMetaMask = async () => {
    try {
      if (!ethereum) {
        alert("Please install MetaMask.");
        return;
      }
      setWalletConnecting(true);
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setCurrentAccount(accounts[0]);
      const formData = {
        user_walletaddress: accounts[0],
      };
      axios
        .post(`${BackendURL}/antsusersignin`, formData)
        .then((res) => {
          const data = res?.data?.data[0];
          // console.log(res.data)
          if (data) {
            const user = {
              userId: data?.user_id,
              displayname: data?.user_fullname,
              useremail: data?.user_email,
              username: data?.user_username,
              profileImg: data?.user_profileimg,
              usersociallinks: data?.user_sociallink,
            };
            setUserData(user);
            setCurrentAccount(data?.user_walletaddress);
            window.localStorage.setItem("user", JSON.stringify(user));
            setWalletConnecting(false);
          } else {
            alert("Wallet Address Not Found.");
            navigate("/signup", {replace: true});
          }
        })
        .catch((error) => {
          console.log(error);
          alert("Wallet Address Not Found.");
          navigate("/signup", {replace: true});
        })
        .finally(() => setWalletConnecting(false));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <TheContext.Provider
      value={{
        currentAccount,
        connectMetaMask,
        setCurrentAccount,
        walletConnecting,
        userData,
        setUserData,
        setButtonBgColor,
        buttonBgColor,
        handleClick,
        sethandleClick,
        scrollName, 
        setscrollName,
        handleClick2,
        sethandleClick2,
      }}
    >
      {children}
    </TheContext.Provider>
  );
}

export const useStateContext = () => useContext(TheContext);
