import React from "react";
import { Link } from "react-router-dom";
import "../styles/Profile.css";

const ProfileObj = ({ handleLogout }) => {
  return (
    <div className="profile__object__wrapper">
      <div className="profile__wrapper__content">
        <Link to={`/profile`} className="my__profile">
          <i className="fa fa-user me-2"></i>
          My Profile
        </Link>
        </div>
        <div className="profile__wrapper__content">
        <div onClick={handleLogout} className="logout__button p-0 text-white">
          <i className="fa fa-sign-out me-2"></i>
          Sign Out
        </div>
      </div>
    </div>
  );
};

export default ProfileObj;
