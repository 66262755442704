import React, { useState, useEffect } from "react";
import axios from "axios";
import { planbg } from "../utils/assets";
import { BackendURL } from "../utils/utils";
import VoteCard from "../components/VoteCard/VoteCard";
import { useStateContext } from "../context/AppContext";
const AllProjects = () => {
  const { userData } = useStateContext();
  const [projects, setProjects] = useState([]);
  const [category, setCategory] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    const request1 = axios.get(`${BackendURL}/projectcategory`);
    const request2 = axios.get(`${BackendURL}/vote/project_all`);

    Promise.all([request1, request2])
      .then(async (responses) => {
        const catg = responses[0].data;
        const project = responses[1].data;
        let filteredData = project;
        // console.log("userData",userData)
        if (userData) {
          filteredData = await Promise.all(project.map(async (element) => {
            const isUserVotedList = element.votes.filter((item) => item.user_id == userData?.userId)
            let isVoted = false
            if (isUserVotedList.length > 0) {
              isVoted = true
            }
            return {
              ...element,
              isVoted: isVoted
            }
          }));
        }
        // console.log("filteredProjects",filteredData)
        
        setCategory(catg);

        const filteredProjects = filteredData.filter((proj) => {
          const titleMatch = proj.projects_title
            .toLowerCase()
            .includes(searchInput?.toLowerCase());
          const categoryMatch = selectedCategory
            ? proj.projects_category_id === parseInt(selectedCategory, 10)
            : true;
          return titleMatch && categoryMatch;
        });

        setProjects(filteredProjects);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [searchInput, selectedCategory, userData]);
  
  //   console.log(projects, 'projects')
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section>
        <div
          className="page-header bg--cover"
          style={{ background: `url(${planbg})` }}
        >
          <div className="container">
            <div className="page-header__content text-center">
              <h2 className="text-uppercase">Projects</h2>
              <p className="text-white">
                Join forces to achieve goals and support important charitable
                causes.
              </p>
            </div>
            <form className="row projects-row">
              <div className="col-lg-6 col-12">
                <input
                  placeholder="Search Projects"
                  className="form-control input-theme"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
              <div className="col-lg-6 col-12 position-relative">
                <select
                  className="form-select input-theme"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option value={""}>Select Projects</option>
                  {category.map((cat, i) => (
                    <option key={i} value={cat.category_id}>
                      {cat.category_name}
                    </option>
                  ))}
                </select>
                <i
                  className="fa fa-chevron-down text-white position-absolute opacity-100 z4"
                  style={{ right: "2rem", top: "1.7vh" }}
                ></i>
              </div>
            </form>
          </div>
        </div>

        <div className="container" style={{ padding: '5rem 0' }}>
          <div className="row gy-5">
            {projects.map((p, i) => (
              <div key={i} className="col-lg-4 col-md-6 col-12 col-12">
                <VoteCard
                  id={p?.projects_id}
                  text={p?.projects_text}
                  title={p?.projects_title}
                  image={p?.projects_image}
                  price={p?.projects_price}
                  slug={p?.projects_slug}
                  item={p}
                  isVoted={p?.isVoted}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default AllProjects;
