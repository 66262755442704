import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { BackendURL } from "../utils/utils";
import { truncate } from "lodash";

const ProductsCard = ({ title, text, link, slug, featuredImg }) => {
  const cardRef = useRef(null);
  useEffect(() => {
    const cards = document.querySelectorAll(".vottingcards");
    let maxHeight = 0;
    cards.forEach((card) => {
      maxHeight = Math.max(maxHeight, card.offsetHeight);
    });
    cards.forEach((card) => {
      card.style.height = `${maxHeight}px`;
    });
  }, []);
  return (
    <Link to={`/product/${slug}`} className="text-decoration-none">
      <div
        className="blog__item vottingcards d-flex flex-column round-theme"
        style={{ cursor: "pointer" }}
        ref={cardRef}
      >
        <div className="blog__thumb round-theme overflow-hidden">
          <img
            src={`${BackendURL}/nodeassets/${featuredImg}`}
            className="img-fluid round-theme"
            alt="featured/img"
            style={{
              height: "25vh",
              width: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="d-flex flex-column card-body">
          <h1 className="blog__meta-tag fw-light">
            {truncate(title, { length: 45 })}
          </h1>
          <p>{truncate(text, { length: 65 })}</p>
        </div>
      </div>
    </Link>
  );
};

export default ProductsCard;
