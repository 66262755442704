import React, { useEffect, useState } from "react";
import axios from "axios";
import { BackendURL } from "../utils/utils";
import Commnuity from "./Commnuity";

const Partners = () => {
  const [title, setTitle] = useState([]);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    axios
      .get(`${BackendURL}/antspartnerstitle`)
      .then((res) => setTitle(res.data))
      .catch((error) => console.log(error));
    
      axios
      .get(`${BackendURL}/ourantspartners`)
      .then((res) => setPartners(res.data))
      .catch((error) => console.log(error));
  }, []);

  const handleClick = (url) => {
    window.open(url, "_blank");
  }

  return (
    <>
      {partners?.length > 0 ? (
        <>
        <section className="partner padding-top padding-bottom">
          <div className="container">
            <div className="section-header text-center">
              <p className="subtitle">{title[0]?.partners_tag}</p>
              <h2>{title[0]?.partners_title}</h2>
            </div>
            <div className="partner__wrapper">
            <div className="row g-0 row-cols-2 row-cols-md-3 row-cols-xl-5 mx-lg-auto">
                {partners?.map((p, i) => (
                  <div className="col" key={i}>
                    <div className="partner__item round-theme" style={{cursor: 'pointer'}} onClick={()=> handleClick(p.partners_url)}>
                      <img width={100} height={100} style={{objectFit: 'contain'}} src={`${BackendURL}/nodeassets/${p?.partners_image}`} alt="partner/img" />
                      <h6 className="mt-3">{p.partners_name}</h6>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <Commnuity />
        </>
      ) : null}
    </>
  );
};

export default Partners;
