import React, { useEffect, useState } from "react";
import axios from "axios";
import { BackendURL } from "../utils/utils";

const Roadmap = () => {
  const [title, setTitle] = useState([]);
  const [sectionData, setSectionData] = useState([]);

  useEffect(() => {
    Promise.all([
      axios.get(`${BackendURL}/howworkstitle`),
      axios.get(`${BackendURL}/howworkssection`),
    ])
      .then((res) => {
        setTitle(res[0].data);
        setSectionData(res[1].data);
      })
      .catch((err) => console.log(err));

    }, []);
    // console.log(`first`, sectionData)
  return (
    <>
      {sectionData?.length > 0 ? (
        <section
          className="roadmap roadmap--style1 padding-top"
          id="roadmap"
        >
          <div className="container">
            <div className="section-header text-center">
              <p className="subtitle">{title[0]?.howworks_tag}</p>
              <h2>{title[0]?.howworks_title}</h2>
            </div>
            <div className="roadmap__wrapper">
              <div className="row gy-4 gy-md-0 gx-5">
                <div className="col-md-6 offset-md-6">
                  <div
                    className=" roadmap__item ms-md-4 aos-init"
                    data-aos="fade-left"
                    data-aos-duration="800"
                  >
                    <div className="roadmap__item-inner">
                      <div className="roadmap__item-content">
                        <div className="roadmap__item-header">
                          <h4>{sectionData[0]?.howworks_title}</h4>
                          <p>Step 1</p>
                        </div>
                        <p>{sectionData[0]?.howworks_text}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className=" roadmap__item ms-auto me-md-4 aos-init"
                    data-aos="fade-right"
                    data-aos-duration="800"
                  >
                    <div className="roadmap__item-inner">
                      <div className="roadmap__item-content">
                        <div className="roadmap__item-header">
                          <h4>{sectionData[1]?.howworks_title}</h4>
                          <p>Step 2</p>
                        </div>
                        <p>{sectionData[1]?.howworks_text}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 offset-md-6">
                  <div
                    className="roadmap__item ms-md-4  aos-init"
                    data-aos="fade-left"
                    data-aos-duration="800"
                  >
                    <div className="roadmap__item-inner">
                      <div className="roadmap__item-content">
                        <div className="roadmap__item-header">
                          <h4>{sectionData[2]?.howworks_title}</h4>
                          <p>Step 3</p>
                        </div>
                        <p>{sectionData[2]?.howworks_text}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="roadmap__item ms-auto me-md-4  aos-init"
                    data-aos="fade-right"
                    data-aos-duration="800"
                  >
                    <div className="roadmap__item-inner">
                      <div className="roadmap__item-content">
                        <div className="roadmap__item-header">
                          <h4>{sectionData[3]?.howworks_title}</h4>
                          <p>Step 4</p>
                        </div>
                        <p>{sectionData[3]?.howworks_text}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 offset-md-6">
                  <div
                    className="roadmap__item ms-md-4  aos-init"
                    data-aos="fade-left"
                    data-aos-duration="800"
                  >
                    <div className="roadmap__item-inner">
                      <div className="roadmap__item-content">
                        <div className="roadmap__item-header">
                          <h4>{sectionData[4]?.howworks_title}</h4>
                          <p>Step 5</p>
                        </div>
                        <p>{sectionData[4]?.howworks_text}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default Roadmap;
