import React, { useEffect, useState } from "react";
import Hero from "../components/Hero";
import Stats from "../components/Stats";
import OurMission from "../components/OurMission";
import Roadmap from "../components/Roadmap";
import Team from "../components/Team";
import AntsWallet from "../components/AntsWallet";
import AntsToken from "../components/AntsToken";
import Benifits from "../components/Benifits";
import Subscription from "../components/Subscription";
import Partners from "../components/Partners";
import Projects from "../components/Projects";
import OurUsers from "../components/OurUsers";
import { useStateContext } from "../context/AppContext";
import { BackendURL, WebsiteUrl } from "../utils/utils";
import { Helmet } from "react-helmet";
import axios from "axios";
import { scroller } from "react-scroll";

const Home = () => {
  const { scrollName, setscrollName,sethandleClick } = useStateContext();

  const [data, setdata] = useState([])

  useEffect(() => {
    if (!scrollName) {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        scroller.scrollTo(scrollName, {
          duration: 500,
          smooth: true,
          offset: -100,
        });
        setscrollName("")
        sethandleClick(false)
        
      }, 1000);
    }

    axios.get(`${BackendURL}/homePageSco/get`)
      .then((response) => {
        setdata(response.data)
      }).catch((err) => {
        console.log(err)
      })
  }, []);

  // const location = useLocation();
  // useEffect(() => {
  //   if (location?.state?.dataObj == null) {
  //     console.log('no location')
  //     window.scrollTo(0, 0);
  //   } else {
  //     console.log('yes location')
  //     setTimeout(() => {
  //       scroller.scrollTo(location?.state.dataObj, {
  //         duration: 500,
  //         smooth: true,
  //         offset: -100,
  //       });
  //     }, 1000);
  //   }
  // }, []);



  return (
    <>
      {
        data[0] && (
          <Helmet>
            <title>{data[0]?.meta_title}</title>

            <meta
              name="description"
              content={data[0]?.meta_description}
            />
            <meta
              name="keywords"
              content={JSON.parse(data[0]?.meta_keywords)}
            />
            <link rel="canonical" href={`${WebsiteUrl}`} />
            <link
              rel="canonical"
              href={`${WebsiteUrl}`}
            />
            <meta name="robots" content="index,follow" />
            <meta property="og:title" content={data[0]?.meta_title} />
            <meta
              property="og:description"
              content={data[0]?.meta_description}
            />
            <meta
              property="og:image"
              content={`${BackendURL}/nodeassets/${data[0]?.meta_img}`}
            />
            <meta property="og:url" content={`${WebsiteUrl}`} />
            <meta
              property="og:url"
              content={`${WebsiteUrl}`}
            />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content={data[0]?.meta_title} />
            <meta name="twitter:title" content={data[0]?.meta_title} />
            <meta
              name="twitter:description"
              content={data[0]?.meta_description}
            />

            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="favicon" href="/favicon.ico" />
            <meta
              name="twitter:image"
              content={`${BackendURL}/nodeassets/${data[0]?.meta_img}`}
            />
          </Helmet>
        )
      }
      <Hero />
      <Stats />
      <OurMission />
      <Roadmap />
      <Projects />
      <AntsWallet />
      <AntsToken />
      <Benifits />
      <Subscription />
      <Team />
      <OurUsers />
      <Partners />
    </>
  );
};

export default Home;
