import React, { useEffect, useState } from "react";
import axios from "axios";
import { BackendURL } from "../../utils/utils";
import { toast } from "react-toastify";

const CashbackRewards = ({ currentAccount }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getCashbackList();
  }, []);
  const getCashbackList = () => {
    axios
      .get(`${BackendURL}/cashback_list`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  };

  const handleRequest = (cashback_list_id) => {
    const formDataCashback = {
      cashback_list_id: cashback_list_id,
      cashback_list_status: "Requested",
    };

    axios
      .post(`${BackendURL}/cashback_list/update`, formDataCashback)
      .then((res) => {
        toast.success("Request is submitted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        getCashbackList();
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {!data?.length > 0 ? (
        <p className="fs-4 fw-bold m-0" style={{ color: "#94a3b8" }}>
          No Rewards
        </p>
      ) : (
        <div className="d-grid align-items-center">
          <h5>Redeem Summary</h5>
          <div className="d-grid align-items-center gap-4">
            {data?.map((d, i) => (
              <div className="d-flex align-items-center" key={i}>
                <div className="d-grid align-items-center">
                  <p className="text-white m-0">
                    You have Earned{" "}
                    <span className="subtitle">${d.cashback_list_price}</span>
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <button
                    disabled={d.cashback_list_status == "Requested" || d.cashback_list_status == "Approved"}
                    type="button"
                    onClick={(e) => {
                      handleRequest(d.cashback_list_id);
                    }}
                    className="default-btn--secondary btn btn-sm ms-4"
                  >
                    {d.cashback_list_status == "Requested"
                      ? "Requested" : d.cashback_list_status == "Approved"
                      ? "Awarded"
                      : "Request"}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CashbackRewards;
