import React, { useState } from "react";
import { metamask, stripeimg } from "../utils/assets";
import { Link } from "react-router-dom";
import StripePaymentModel from "./StripePaymentModel";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
  "pk_test_51HSL0hLGu98seKpWSH7iILf0F8kRZnn6wpAssBIDCvXhO9GuJWQozOVfaKriRuaYRI1cwvj3dPxEz5uAunvTaW0B00aZSftnBh"
);

const WalletModal = ({ p, i, handlePricings }) => {
  const [openStripeModal, setOpenStripeModal] = useState(false);

  return (
    <>
      <div
        className="wallet-modal modal fade"
        id={`wallet-option-${i}`}
        tabIndex="-1"
        aria-labelledby="choose-wallet"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="choose-wallet">
                Connect Payment Method
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body py-0">
              <ul className="wallet__list justify-content-center">
                <li className="wallet__list-item">
                  <Link
                    href="#"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => handlePricings(p?.plans_id, p)}
                  >
                    <span>
                      <img src={metamask} alt="metamask" />
                    </span>
                  </Link>
                </li>
                <li className="wallet__list-item ms-4">
                  <Link
                    href="#"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    // onClick={() => handleStripe(p?.plans_id, p)}
                    onClick={() => setOpenStripeModal(true)}
                  >
                    <span>
                      <img src={stripeimg} alt="stripe/img" />
                    </span>
                    {/* <p style={{height: '3vh', marginTop: '1vh'}}>PAY WITH <br/><span className="subtitle">Card</span></p> */}
                  </Link>
                </li>
              </ul>
              <p>
                By choosing payment method, you agree to our Terms of Service
                and our Privacy Policy.
              </p>
            </div>
          </div>
        </div>
      </div>
      {openStripeModal && (
        <Elements stripe={stripePromise}>
          <StripePaymentModel
            id={p?.plans_id}
            item={p}
            setOpenStripeModal={setOpenStripeModal}
          />
        </Elements>
      )}
    </>
  );
};

export default WalletModal;
