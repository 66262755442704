import React from "react";
import { BackendURL } from "../../utils/utils";

const UserBadges = ({ userBadges }) => {
  // console.log(userBadges, "badge");
  return (
    <>
      {userBadges?.badgeLevel?.length < 1 ? (
        <p className="fs-4 fw-bold m-0" style={{ color: "#94a3b8" }}>
          User Earned No Badges Yet!
        </p>
      ) : (
        <div className="d-grid align-items-center gap-3">
          <h5 className="text-white">You Have Earned Badges.</h5>
          <div className="badges__item__grid">
            {userBadges?.badgeLevel?.map((b, i) => (
              <div className="badges__item" key={i}>
                <img
                  src={`${BackendURL}/nodeassets/${b?.badge_image}`}
                  alt="bages/img"
                  width={65}
                  height={65}
                />
                <p className="text-white m-0">
                  <span className="subtitle">{b?.badge_name}</span>
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default UserBadges;
