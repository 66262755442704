import React, { useEffect, useState } from "react";
import { BackendURL } from "../utils/utils";
import axios from "axios";

const Benifits = () => {
  const [title, setTitle] = useState([]);
  const [sectionData, setSectionData] = useState([]);

  useEffect(() => {
    Promise.all([
      axios.get(`${BackendURL}/benefitstitle`),
      axios.get(`${BackendURL}/signupbenefits`),
    ])
      .then((res) => {
        setTitle(res[0].data);
        setSectionData(res[1].data);
      })
      .catch((err) => console.log(err));
  }, []);

  const faqs = {
    faq1: [
      {
        title: "Receive more Cashback",
        text: "When you sign up with your visa credit card, you will be able to receive cashback based on your monthly subscription and participation in our charity projects.",
      },
      {
        title: "Making a Difference",
        text: "Your action counts! Through your direct participation, you will be able to impact charity projects all around the world.",
      },
    ],
    faq2: [
      {
        title: "Airdrops, and surprises",
        text: "Free participation in various airdrops and dedicated events, and especially in our surprises for our community.",
      },
      {
        title: "Fair and Democratic",
        text: "Our community decides which project to receive the donation, ensuring a fair and democratic process.",
      },
    ],
  };

  return (
    <>
      {sectionData?.length > 0 ? (
        <section id="benefits" className="faq padding-top">
          <div className="container">
            <div
              className="section-header text-center"
              // data-aos="fade-up"
              // data-aos-duration="1000"
            >
              <p className="subtitle">{title[0]?.benefits_tag}</p>
              <h2>{title[0]?.benefits_title}</h2>
            </div>
            <div className="faq__wrapper">
              <div className="row benifits__row">
                {sectionData?.map((val, i) => (
                  <div key={i} className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div
                      className="accordion"
                      id={`accordion-${val.benefits_title
                        ?.replace(/\s+/g, "")
                        ?.toLowerCase()}`}
                    >
                      <div
                        className="accordion__item"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      >
                        <div className="accordion__header">
                          <button
                            className="accordion__button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-${val.benefits_id}`}
                            aria-expanded="false"
                            aria-controls={`collapse-${val.benefits_id}`}
                          >
                            {val.benefits_title}
                            <span className="plus-icon"></span>
                          </button>
                        </div>
                        <div
                          id={`collapse-${val.benefits_id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby="faq1"
                          data-bs-parent={`#accordion-${val.benefits_title
                            ?.replace(/\s+/g, "")
                            ?.toLowerCase()}`}
                        >
                          <div className="accordion__body">
                            {val.benefits_text}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default Benifits;
