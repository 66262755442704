import React, { useRef, useEffect, useState } from "react";
import { BackendURL, contractAddress } from "../../utils/utils";
import { truncate } from "lodash";
import { Link } from "react-router-dom";
import { useStateContext } from "../../context/AppContext";
import Web3 from "web3";
import ABI from "../../utils/abi";
import axios from "axios";
const VoteCard = ({ id, text, title, image, price, isTruncate, slug, item, isVoted }) => {
  const cardRef = useRef(null);
  const { buttonBgColor } = useStateContext();

  useEffect(() => {
    const cards = document.querySelectorAll(".vottingcards");
    let maxHeight = 0;
    cards.forEach((card) => {
      maxHeight = Math.max(maxHeight, card.offsetHeight);
    });
    cards.forEach((card) => {
      card.style.height = `${maxHeight}px`;
    });
  }, []);
  return (
    <Link to={`/project/${slug}`} className="text-decoration-none">
      <div
        className="blog__item vottingcards d-flex flex-column round-theme"
        style={{ cursor: "pointer" }}
        ref={cardRef}
      >
        <div className="blog__thumb round-theme overflow-hidden">
          <img
            src={`${BackendURL}/nodeassets/${image}`}
            className="img-fluid round-theme"
            alt={`project-img/${id}`}
            style={{
              height: "25vh",
              width: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="d-flex flex-column card-body">
          <h1 className="blog__meta-tag fw-light">
            {isTruncate ? truncate(title, { length: 45 }) : title}
          </h1>
          <p>{isTruncate ? truncate(text, { length: 65 }) : text}</p>
        </div>
        <div className="project_detail_donations_wrapper_card d-grid align-items-start mt-1">
          <div className="row project_detail_donations_card">
            <div className=" col-md-4 col-sm-6 col-6" key={0}>
              <div className="project_detail_donations_item_card">
                <h6 className="m-0">
                  Total Raised
                </h6>

                <h3 className="theme-color m-0">{item?.projects_total_raised} USD</h3>
              </div>
            </div>
            <div className=" col-md-4 col-sm-6 col-6" key={1}>
              <div className="project_detail_donations_item_card">
                <h6 className="m-0">
                  Donors
                </h6>
                <h3 className="theme-color m-0">{item?.projects_donors}</h3>
              </div>
            </div>
            <div className=" col-md-4 col-sm-6 col-6" key={2}>
              <div className="project_detail_donations_item_card">
                <h6 className="m-0">
                  End Benificeries
                </h6>
                <h3 className="theme-color m-0">{item?.projects_end_benificeries}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer d-flex align-items-center justify-content-between pb-3  ">
          {/* {item ?
            item.votes_length ? */}
          <p className="subtitle">Votes: {Number(item.votes_length) + Number(item.projects_opening_votes)}</p>
          {/* : "" : ""} */}
          <button
            className="btn btn-sm text-white"
            style={{ background: `${buttonBgColor[0]?.button_bgcolor}`, padding: "0.1rem 0.7rem" }}
            onClick={(e) => {
            }}
          >
            {isVoted ?
              "Vote"
              :
              "Vote"}
          </button>

          <button
            className="btn btn-sm"
            style={{ background: 'transparent', padding: "0.1rem 0.7rem", border: '1px solid #0099FF', color: "#0099FF" }}
          >
            {isVoted ?
              "Donate"
              :
              "Donate"}
          </button>
        </div>
      </div>
    </Link>
  );
};

export default VoteCard;
