import React, { useEffect } from "react";
import { checkmark } from "../utils/assets";
import { Link } from "react-router-dom";
import axios from "axios";
import { useStateContext } from "../context/AppContext";
import { BackendURL } from "../utils/utils";

const Success = () => {
  // console.log(`success`)
  const { userData } = useStateContext();

  const handleGoToProjects = () => {
    if (userData) {
      axios
        .get(`${BackendURL}/getplanstatus/${userData?.userId}`)
        .then((res) => {
          const purchasePlans = res.data;
          const latestPlan = purchasePlans[purchasePlans.length - 1]; // Get the latest record
  
          const updatedData = {
            ...latestPlan,
            purchase_plan_status: "Update",
          };
  
          // console.log(updatedData);
  
          axios
            .put(
              `${BackendURL}/updateplanstatus/${latestPlan.plans_id}`,
              { purchase_plan_status: updatedData.purchase_plan_status }
            )
            .then((response) => {})
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }
  };
  

  return (
    <>
      <div className="container">
        <div className="d-flex align-items-center justify-content-center min-vh-100">
          <div className="d-flex align-items-center flex-column gap-4 bg-black border border-dark shadow-lg round-theme p-4">
            <h4 className="text-center">You Have Successfully <br/> Subscribed</h4>
            <img src={checkmark} alt="chec-mark/svg" width={55} height={55} />
            <Link
              className="d-flex align-items-center w-100"
              to={`/`}
              onClick={handleGoToProjects}
            >
              <button
                type="button"
                className="default-btn py-3 w-100 btn"
                style={{ lineHeight: "normal" }}
              >
                Go To Projects
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Success;
