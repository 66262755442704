import React from 'react'
import { Link } from 'react-router-dom'
import Subscription from '../Subscription'

const UserMoreVotes = () => {
  return (
    <>
      <Link
        to={`/`}
      >
        <span className='subtitle'>Add More Votes</span>
        <i className="fa fa-arrow-right-long ms-2"></i>
      </Link>
      <Subscription ifTitle ifCardOnStart ifPaddingTop />
    </>
  )
}

export default UserMoreVotes