import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { ToastContainer } from 'react-toastify'

const Layout = ({ children }) => {
  return (
    <>
        <Navbar />
        <ToastContainer />
        <main>
            {children}
            <Footer />
        </main>
    </>
  )
}

export default Layout