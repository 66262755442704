import React, { useEffect, useRef, useState } from "react";
import { planbg } from "../utils/assets";
import { useStateContext } from "../context/AppContext";
import "../styles/Profile.css";
import { BackendURL } from "../utils/utils";
import { Link } from "react-router-dom";
import axios from "axios";
import VottedProjects from "../components/ProfileTabs/VottedProjects";
import UserBillings from "../components/ProfileTabs/UserBillings";
import UserBadges from "../components/ProfileTabs/UserBadges";
import UserMoreVotes from "../components/ProfileTabs/UserMoreVotes";
import CashbackRewards from "../components/ProfileTabs/CashbackRewards";
import { toast } from "react-toastify";

const Profile = () => {
  const { userData, currentAccount } = useStateContext();
  const [projects, setProjects] = useState([]);
  const [membership, setMembership] = useState([]);
  const menuButtonsRefs = useRef([]);
  const [activeButton, setActiveButton] = useState(0);
  const [userBadges, setUserBadges] = useState([]);
  const [planType, setPlanType] = useState([]);

  const menuButtons = [
    {
      id: 1,
      label: "Votted Projects",
      component: <VottedProjects projects={projects} />,
    },
    {
      id: 2,
      label: "Billing",
      component: <UserBillings membership={membership} planType={planType} />,
    },
    {
      id: 3,
      label: "Earned Badges",
      component: <UserBadges userBadges={userBadges} />,
    },
    {
      id: 4,
      label: "Add More Votes",
      component: <UserMoreVotes />,
    },
    {
      id: 5,
      label: "Cashback & Rewards",
      component: <CashbackRewards currentAccount={currentAccount} />,
    },
  ];

  useEffect(() => {
    if (userData?.userId) {
      const fetchedCategories = axios.get(`${BackendURL}/projectcategory`);
      const fetchProjects = axios.get(
        `${BackendURL}/vote/project_all/${userData?.userId}`
      );
      const fetchMembership = axios.get(
        `${BackendURL}/getMembershipType/${userData?.userId}`
      );
      const fetchBadges = axios.get(
        `${BackendURL}/badgescriteria/${userData?.userId}`
      );
      const fetchRemainVotes = axios.get(
        `${BackendURL}/getRemainingVotes/${userData?.userId}`
      );
      const fetchPlanTypoe = axios.get(
        `${BackendURL}/getPlanType/${userData?.userId}`
      );

      Promise.all([
        fetchedCategories,
        fetchProjects,
        fetchMembership,
        fetchBadges,
        fetchRemainVotes,
        fetchPlanTypoe,
      ])
        .then(
          ([
            CategoriesRes,
            projectsRes,
            membershipRes,
            badgesRes,
            remainVotesRes,
            planTypeRes,
          ]) => {
            setProjects(projectsRes?.data);
            setUserBadges(badgesRes?.data);
            setPlanType(planTypeRes?.data);

            const membershipData = membershipRes?.data;
            const votesData = remainVotesRes?.data;

            let allowedArray = [];
            CategoriesRes?.data.forEach((element) => {
              const categoryId = element.category_id;
              let allowedToCategory = 0;
              //
              membershipData.forEach((elementMembership) => {
                JSON.parse(elementMembership.plan_numofvotes)?.forEach(
                  (category) => {
                    if (categoryId == category.category_id) {
                      allowedToCategory += Number(category.numOfVotes);
                    }
                  }
                );
              });
              allowedArray.push({
                category_id: categoryId,
                category_name: element.category_name,
                allowedToCategory: allowedToCategory,
              });
            });
            let allMembershiptData = [];
            allowedArray.forEach((element) => {
              const votes = votesData?.filter(
                (vote) => vote.projects_category_id === element.category_id
              );
              const remainingVotes =
                Number(element.allowedToCategory) - votes.length;
              allMembershiptData.push({
                category_id: element?.category_id,
                category_name: element.category_name,
                totalAllowedVotes: element.allowedToCategory,
                votted: votes.length,
                remainingVotes: remainingVotes,
              });
            });
            setMembership(allMembershiptData);
          }
        )
        .catch((error) => console.log(error));
    }
  }, [userData]);

  const handleBtnClick = (index) => {
    menuButtonsRefs.current.forEach((menuRef, i) => {
      if (menuRef) {
        if (i === index) {
          menuRef.classList.add("activeMenuBtns");
        } else {
          menuRef.classList.remove("activeMenuBtns");
        }
      }
    });

    setActiveButton(index);
  };
  const handleCopyText = () => {
    navigator.clipboard
      .writeText(currentAccount)
      .then(() => {
        toast.success("Copied", {
          position: "top-right",
        });
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };
  const goToSocialLink = (link) => {
    window.open(link, "_blank");
  };
  // console.log(projects, 'projects');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section>
      <div
        className="page-header bg--cover"
        style={{ background: `url(${planbg})` }}
      ></div>
      <div className="section__padding position-relative">
        <div className="container">
          <div className="row profile__wrapper_row">
            <div className="col-lg-9 col-md-9 col-sm-12 col-12">
              <div className="profile__wrapper">
                <div className="d-flex align-items-center">
                  <img
                    src={
                      userData?.profileImg
                        ? `${BackendURL}/nodeassets/${userData?.profileImg}`
                        : null
                    }
                    className="user_profile_img"
                    alt="user/img"
                  />
                </div>
                <div className="profile_info_content">
                  <div className="profile__info">
                    <div className="d-grid align-items-center">
                      <h4 className="m-0">{userData?.displayname}</h4>
                      <p className="theme-color fs-5 m-0">
                        @{userData?.username}
                      </p>
                    </div>
                    {currentAccount ? (
                      <div className="profile__info_wallet_address mt-md-2 d-flex align-items-center">
                        <p className="m-0 d-flex align-items-center text-white">
                          <i className="fa-solid fa-wallet fs-5 me-2"></i>
                          {currentAccount?.substring(0, 7)}...
                          {currentAccount?.substring(
                            currentAccount?.length - 7
                          )}
                        </p>
                        <button
                          type="button"
                          onClick={handleCopyText}
                          className="btn btn-sm default-btn--secondary fw-bold text-white ms-2"
                          style={{ fontSize: "0.7rem" }}
                        >
                          Copy
                        </button>
                      </div>
                    ) : null}
                    <div className="d-flex align-items-center gap-2 mt-0 mt-md-2">
                      <i className="fa fa-link text-white"></i>
                      <span
                        className="theme-color"
                        onClick={() =>
                          goToSocialLink(userData?.usersociallinks)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {userData?.usersociallinks}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    {userBadges?.badgeLevel?.length < 1 ? null : (
                      <div className="d-flex align-items-center gap-2">
                        {userBadges?.badgeLevel?.map((b, i) => (
                          <div className="d-flex align-items-center" key={i}>
                            <img
                              src={`${BackendURL}/nodeassets/${b?.badge_image}`}
                              alt="bages/img"
                              width={31}
                              height={31}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
              <Link to={`/profile/${userData?.userId}`}>
                <button
                  type="button"
                  className="default-btn default-btn--small btn profile__update__button"
                >
                  Update Profile
                </button>
              </Link>
            </div>
          </div>
          <nav className="buttons__nav">
            {menuButtons.map((button, index) => (
              <button
                key={button.id}
                ref={(el) => (menuButtonsRefs.current[index] = el)}
                className={
                  activeButton === index ? "activeMenuBtns" : "outlineMenuBtns"
                }
                onClick={() => handleBtnClick(index)}
              >
                <span>{button.label}</span>
              </button>
            ))}
          </nav>

          <div className="d-grid align-items-center">
            {menuButtons.map(
              (button, index) =>
                activeButton === index && (
                  <div
                    key={button.id}
                    id={`zero${index + 1}`}
                    className="onStep fadeIn"
                  >
                    {button.component}
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
