import React from "react";

const UserBillings = ({ membership, planType }) => {
  // console.log(planType, "membership");
  return (
    <>
      {!membership?.length > 0 ? (
        <p className="fs-4 fw-bold m-0" style={{ color: "#94a3b8" }}>
          No Billings
        </p>
      ) : (
        <div className="billing__category__wrapper d-grid align-items-start">
          <div className="d-grid align-items-center">
            <h5>SUBSCRIPTION SUMMARY</h5>
            <p className="text-white fw-bold mb-1">You Purchased:</p>
            {planType?.map((p, i) => (
              <p className="m-0" key={i}>
                <span className="subtitle">
                  {p?.plan_membership} Membership
                </span>
              </p>
            ))}
          </div>
          <h5 className="m-0 remaining__title">Votes Remaining</h5>
          <div className="row billings__category__row">
            {membership?.map((v, i) => (
              <div className="col-lg-3 col-md-4 col-sm-6 col-6" key={i}>
                <div className="billing__category__item">
                  <h6 className="m-0">
                    Projects for{" "}
                    <span className="theme-color">{v.category_name}</span>
                  </h6>
                  <h3 className="theme-color m-0">{v.remainingVotes}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default UserBillings;
