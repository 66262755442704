import React from "react";
import { communitybg, openseaimg } from "../utils/assets";
import { useStateContext } from "../context/AppContext";

const Commnuity = () => {
  const {buttonBgColor} = useStateContext();
  return (
    <>
      <section
        className="community padding-top padding-bottom"
        style={{background: `url(${communitybg})`}}
      >
        <div className="container">
          <div className="comminity__wrapper">
            <div className="section-header text-center">
              <p className="subtitle">Our Community</p>
              <h2>Join Our Cumminity and get early access</h2>
            </div>
            <div className="btn-group justify-content-center">
              <a href="https://discord.gg/HweUTFaG" target="_blank" className="default-btn" style={{
                          background: `${buttonBgColor[0]?.button_bgcolor}`
                        }}>
                <span>
                  <i className="fab fa-discord"></i> Join Discord
                </span>
              </a>
              {/* <a href="#" className="default-btn">
                {" "}
                <span>
                  <img
                    src={openseaimg}
                    alt="opensea icon"
                    width="20"
                    height="20"
                  />{" "}
                  Join Opensea
                </span>{" "}
              </a> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Commnuity;
