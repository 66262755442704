import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { footerbg, whitelogo } from "../utils/assets";
import axios from "axios";
import { BackendURL } from "../utils/utils";

const Footer = () => {
  const [navBrand, setNavBrand] = useState([]);
  const [socials, setSocials] = useState([]);
  useEffect(() => {
    axios
      .get(`${BackendURL}/antsbrand`)
      .then((res) => setNavBrand(res.data))
      .catch((error) => console.log(error));

    axios
      .get(`${BackendURL}/antsfooter`)
      .then((res) => setSocials(res.data))
      .catch((error) => console.log(error));
  }, []);
  return (
    <>
      {navBrand?.length > 0 ? (
        <footer className="footer">
          <div className="footer__wrapper padding-top padding-bottom">
            <div className="container">
              <div className="footer__content text-center">
                <Link to={"/"} className="mb-4 d-inline-block">
                  <img
                    src={`${BackendURL}/nodeassets/${navBrand[0]?.antsbrand_img}`}
                    alt="Logo"
                  />
                </Link>
                {socials?.length > 0 ? (
                  <ul className="social justify-content-center">
                    {socials.map((s, i) => {
                      if (s?.footer_link?.includes("twitter.com")) {
                        return (
                          <li className="social__item" key={i}>
                            <Link to={s.footer_link} target="_blank" className="social__link">
                              <i className="fab fa-twitter"></i>
                            </Link>
                          </li>
                        );
                      } else if (s?.footer_link?.includes("discord.com")) {
                        return (
                          <li className="social__item" key={i}>
                            <Link to={s.footer_link} target="_blank" className="social__link">
                              <i className="fab fa-discord"></i>
                            </Link>
                          </li>
                        );
                      } else if (s?.footer_link?.includes("instagram.com")) {
                        return (
                          <li className="social__item" key={i}>
                            <Link to={s.footer_link} target="_blank" className="social__link">
                              <i className="fab fa-instagram"></i>
                            </Link>
                          </li>
                        );
                      } else if (s?.footer_link?.includes("reddit.com")) {
                        return (
                          <li className="social__item" key={i}>
                            <Link to={s.footer_link} target="_blank" className="social__link">
                              <i className="fab fa-reddit-alien"></i>
                            </Link>
                          </li>
                        );
                      } else if (s?.footer_link?.includes("facebook.com")) {
                        return (
                          <li className="social__item" key={i}>
                            <Link to={s.footer_link} target="_blank" className="social__link">
                              <i className="fab fa-facebook-f"></i>
                            </Link>
                          </li>
                        );
                      } else if (s?.footer_link?.includes("discord")) {
                        return (
                          <li className="social__item" key={i}>
                            <Link to={s.footer_link} target="_blank" className="social__link">
                              <i className="fab fa-discord"></i>
                            </Link>
                          </li>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </ul>
                ) : null}
              </div>
            </div>
          </div>
          <div className="footer__copyright">
            <div className="container">
              <div className="d-flex align-items-center justify-content-between py-4">
                <p className="mb-0">
                  &copy; Ants Protocol 2023 | All Rights Reserved
                </p>
                <p className="mb-0">
                  Developed by{" "}
                  <a href="https://unialsolutions.com" target="_blank" rel="noopener noreferrer">UNIAL</a>
                </p>
              </div>
            </div>
          </div>
        </footer>
      ) : null}
    </>
  );
};

export default Footer;
