import React, { useState, useEffect } from 'react'
import '../styles/productDesc.css'
import axios from 'axios';
import { BackendURL, WebsiteUrl } from '../utils/utils';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
export default function ProductDescription() {
    const { slug } = useParams();
    const [data, setdata] = useState([])
    const [mulitImages, setmulitImages] = useState([])
    const [proCate, setproCate] = useState([])
    const [proSubCate, setproSubCate] = useState([])

    const fetchProducts = () => {
        axios.get(`${BackendURL}/antsproducts`)
            .then((res) => {
                const filteredData = res.data.filter((t) => t.products_slug == slug)
                setdata(filteredData)
                setmulitImages(JSON.parse(filteredData[0].products_multipleImgs))

            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchCategories = () => {
        axios.get(`${BackendURL}/productscategory`)
            .then((res) => {
                setproCate(res.data)
            })
            .catch((error) => {
                console.log(error);
            });

        axios.get(`${BackendURL}/productsubcategory`)
            .then((response) => {
                setproSubCate(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        fetchProducts();
        fetchCategories();
    }, []);
    return (
        <>
            {
                data[0] && (
                    <Helmet>
                        <title>ANTS Protocol - {data[0]?.products_metatitle}</title>

                        <meta
                            name="description"
                            content={data[0]?.products_metadescription}
                        />
                        <meta
                            name="keywords"
                            content={JSON.parse(data[0]?.products_metakeywords)}
                        />
                        <link rel="canonical" href={`${WebsiteUrl}/commerce`} />
                        <link
                            rel="canonical"
                            href={`${WebsiteUrl}/product/${data[0]?.products_slug}`}
                        />
                        <meta name="robots" content="index,follow" />
                        <meta property="og:title" content={data[0]?.products_title} />
                        <meta
                            property="og:description"
                            content={data[0]?.products_description}
                        />
                        <meta
                            property="og:image"
                            content={`${BackendURL}/nodeassets/${data[0]?.products_featuredImg}`}
                        />
                        <meta property="og:url" content={`${WebsiteUrl}/commerce`} />
                        <meta
                            property="og:url"
                            content={`${WebsiteUrl}/product/${data[0]?.products_slug}`}
                        />
                        <meta property="og:type" content="website" />
                        <meta name="twitter:card" content={data[0]?.products_title} />
                        <meta name="twitter:title" content={data[0]?.products_title} />
                        <meta
                            name="twitter:description"
                            content={data[0]?.products_metadescription}
                        />
                        <meta
                            name="twitter:image"
                            content={`${BackendURL}/nodeassets/${data[0]?.products_featuredImg}`}
                        />
                    </Helmet>
                )
            }
            <section>
                <div className="container" style={{ padding: "2rem" }}>
                    <div className="row">
                        <div className='col-lg-5 col-md-5 pt-1'>
                            <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active" data-bs-interval="10000">
                                        <img src={`${BackendURL}/nodeassets/${data[0]?.products_featuredImg}`} className="d-block product-slider-img" alt="..." />
                                    </div>
                                    {
                                        mulitImages?.map((t, i) => (
                                            <div className="carousel-item" data-bs-interval="2000" key={i}>
                                                <img src={`${BackendURL}/nodeassets/${t}`} className="d-block product-slider-img" alt="..." />
                                            </div>
                                        ))
                                    }
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon bg-dark" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                                    <span className="carousel-control-next-icon bg-dark" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-7 pt-1'>
                            <div style={{ position: "relative", height: "100%" }}>
                                <h4>{data[0]?.products_title}</h4>
                                <p className='mt-5'>Category:  <span className='text-white '>{proCate?.find((item) => item?.product_category_id === data[0]?.products_category_id)?.product_category_name}</span></p>
                                <p>Sub Category:  <span className='text-white '>{proSubCate?.find((item) => item?.product_subcategory_id === data[0]?.products_subcategory_id)?.product_subcategory_name}</span></p>
                                <p>About Product:  <span className='text-white '>{data[0]?.products_text}</span></p>
                                <a href={`${data[0]?.products_affiliateurl}`} className='btn buy-btn mt-4' target='_blank'>Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col'>
                            <h3>Description:</h3>
                            <div className='mt-2 product-desc' dangerouslySetInnerHTML={{ __html: data[0]?.products_description }} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
