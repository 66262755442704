import React, { useEffect, useState } from 'react'
import { blogHome } from '../utils/assets'
import axios from 'axios'
import { BackendURL , WebsiteUrl} from '../utils/utils'
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function Blogs() {
    const [data, setdata] = useState([])
    const [data2, setdata2] = useState([])
    useEffect(() => {
        axios.get(`${BackendURL}/blog/get`)
            .then((response) => {
                setdata(response.data)
            }).catch((err) => {
                console.log(err)
            })

        axios.get(`${BackendURL}/homeBlogSco/get`)
            .then((response) => {
                setdata2(response.data)
            }).catch((err) => {
                console.log(err)
            })
}, [])
return (
    <>
    {
        data2[0] && (
          <Helmet>
            <title>{data2[0]?.meta_title}</title>

            <meta
              name="description"
              content={data2[0]?.meta_description}
            />
            <meta
              name="keywords"
              content={JSON.parse(data2[0]?.meta_keywords)}
            />
            <link rel="canonical" href={`${WebsiteUrl}`} />
            <link
              rel="canonical"
              href={`${WebsiteUrl}`}
            />
            <meta name="robots" content="index,follow" />
            <meta property="og:title" content={data2[0]?.meta_title} />
            <meta
              property="og:description"
              content={data2[0]?.meta_description}
            />
            <meta
              property="og:image"
              content={`${BackendURL}/nodeassets/${data2[0]?.meta_img}`}
            />
            <meta property="og:url" content={`${WebsiteUrl}`} />
            <meta
              property="og:url"
              content={`${WebsiteUrl}`}
            />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content={data2[0]?.meta_title} />
            <meta name="twitter:title" content={data2[0]?.meta_title} />
            <meta
              name="twitter:description"
              content={data2[0]?.meta_description}
            />

            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="favicon" href="/favicon.ico" />
            <meta
              name="twitter:image"
              content={`${BackendURL}/nodeassets/${data2[0]?.meta_img}`}
            />
          </Helmet>
        )
      }
        <section className="page-header bg--cover" style={{ backgroundImage: `url(${blogHome})` }}>
            <div className="container">
                <div className="page-header__content text-center">
                    <h3 className="text-uppercase">Our Blog Post</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center mb-0">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Blog</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </section>

        <div className="blog padding-top padding-bottom">
            <div className="container">
                <div className="blog__wrapper">
                    <div className="row g-4">
                        {
                            data?.map((t, i) => (
                                <div className="col-lg-4 col-md-6" key={i}>
                                    <div className="blog__item">
                                        <div className="blog__inner">
                                            <div className="blog__thumb">
                                                <img src={`${BackendURL}/nodeassets/${t.blog_img}`} alt="Blog Images" />
                                            </div>
                                            <div className="blog__content">
                                                <div className="blog__content-top">
                                                    <span className="blog__meta-tag">{t.blog_category}</span>
                                                    <h4>
                                                        <Link to={`/blog/${t.blog_slug}`}>
                                                            {t.blog_title}
                                                        </Link>
                                                    </h4>
                                                    <ul className="blog__meta d-flex flex-wrap align-items-center">
                                                        <li className="blog__meta-author">
                                                            <a href="#">
                                                                <span>
                                                                    <i className="fa-solid fa-user" />
                                                                </span>{" "}
                                                                Jhon Doe
                                                            </a>
                                                        </li>
                                                        <li className="blog__meta-date">
                                                            <a href="#">
                                                                <span>
                                                                    <i className="fa-solid fa-calendar-days" />
                                                                </span>{" "}
                                                                {t.blog_postingDate}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <p dangerouslySetInnerHTML={{ __html: t?.blog_desc }} />
                                                <div className="blog__content-bottom">
                                                    <Link to={`/blog/${t.blog_slug}`} className="text-btn">
                                                        Read More
                                                    </Link>
                                                    <a href="#" className="blog__meta-comment">
                                                        <i className="fa-solid fa-message" />
                                                        <span className="position-absolute top-0 start-100 translate-middle rounded-circle">
                                                            2
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
)
}
