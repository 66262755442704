import React from "react";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/lightcase.css";
import "./assets/css/style.css";
import "./index.css";

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Layout from "./layout/Layout";
import App from "./App";
import AppContext from "./context/AppContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppContext>
        <Layout>
          <App />
        </Layout>
      </AppContext>
    </BrowserRouter>
  </React.StrictMode>
);
