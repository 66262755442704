import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BackendURL } from '../utils/utils'
import { useNavigate } from 'react-router-dom'
import ProductsCard from '../components/ProductsCard'
export default function Category() {
    const navigate = useNavigate();
    const [data, setdata] = useState([])
    const [category, setcategory] = useState('')
    const [categoryId, setcategoryId] = useState('')
    const [subCategory, setsubCategory] = useState('')
    const [subCateId, setsubCateId] = useState('')
    const [subCateSidebar, setsubCateSidebar] = useState([])
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setfilteredProducts] = useState([])

    const [searchbar, setsearchbar] = useState('')

    const handleFilter = () => {
        if (!searchbar)
            return

        const foundItem = data.filter(t =>
            t.products_title.toLowerCase().startsWith(searchbar.toLowerCase())
        );
        setfilteredProducts(foundItem)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            const currentURL = window.location.href;
            const url = new URL(currentURL);
            const pathname = url.pathname;
            const pathnameParts = pathname.split('/');
            const cate = pathnameParts[pathnameParts.length - 1];
            const category = decodeURIComponent(cate);
            setcategory(category)

            const searchParams = url.searchParams;
            const subcategory = searchParams.get('subcategory');
            setsubCategory(subcategory)

            axios.get(`${BackendURL}/productscategory`)
                .then((res) => {
                    const mainCategory = res.data.filter((t) => t?.product_category_name.replace(/\s+/g, "-") == category)
                    const id = mainCategory[0].product_category_id
                    if (id) {
                        setcategoryId(id)
                        axios.get(`${BackendURL}/productsubcategory`)
                            .then((response) => {
                                const filteredSubCategory = response.data.filter((t) => t?.product_category_id == id)
                                setsubCateSidebar(filteredSubCategory)

                                const filter2 = response.data.filter((t) => t.product_subcategory_name.replace(/\s+/g, "-").replace(/&/g, "and") == subcategory)
                                setsubCateId(filter2[0]?.product_subcategory_id)
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                    }
                })
                .catch((error) => {
                    console.log(error);
                });


        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    const handleCateClick = () => {
        navigate(`/category/${category}`)
        setsubCategory("")
        setsubCateId("")
    }

    const handleSubCateClick = (t) => {
        navigate(`/category/${category}?${'subcategory=' + t.product_subcategory_name.replace(/\s+/g, "-").replace(/&/g, "and")}`)
        setsubCategory(t.product_subcategory_name.replace(/\s+/g, "-").replace(/&/g, "and"))
        setsubCateId(t.product_subcategory_id)
    }

    const fetchProducts = () => {
        setLoading(true);
        axios.get(`${BackendURL}/antsproducts`)
            .then((res) => {
                setProducts(res.data);
                if (subCategory) {
                    const filtered = res.data.filter((t) => t.products_subcategory_id == subCateId)
                    setfilteredProducts(filtered)
                    setdata(filtered)
                } else {
                    const filtered = res.data.filter((t) => t.products_category_id == categoryId)
                    setfilteredProducts(filtered)
                    setdata(filtered)
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchProducts();
    }, [categoryId, subCateId]);

    return (
        <section>
            <div className="container" style={{ padding: "2rem" }}>
                <div className="row">
                    <div className="col-lg-3 p-0 mt-1">
                        <div className='border' style={{ margin: "0 15px" }}>
                            <div className="text-center category fw-bold" style={{ padding: "8.9px" }} onClick={handleCateClick}>{category.replace(/-/g, " ")}</div>
                            {
                                subCateSidebar?.map((t, i) => (
                                    <div key={i} className="text-center category" style={{ padding: "8.9px" }} onClick={() => handleSubCateClick(t)} >{t.product_subcategory_name}</div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="col-lg-9 mt-1">
                        <div className='row'>
                            <div className='col-lg-8 col-md-7'><h3 className='text-center'>{category.replace(/-/g, " ")}</h3></div>
                            <div className='col-lg-4 col-md-5'>
                                <div className='row'>
                                    <div className='col-8 p-0'>
                                        <input placeholder='Enter The Title' value={searchbar} onChange={e => setsearchbar(e.target.value)} className='form-control input-theme bg-black shadow-sm round-theme' style={{ height: "40px" }} />
                                    </div>
                                    <div className='col-4'>
                                        <button className='btn round-theme' onClick={handleFilter} style={{ backgroundColor: "#0099FF", color: "white" }}>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row gy-5" style={{ padding: "20px" }}>
                            {!loading
                                ? filteredProducts.length !== 0 ?
                                    filteredProducts?.map((p, i) => (
                                        <div key={i} className="col-lg-4 col-md-6 col-12 col-12">
                                            <ProductsCard
                                                title={p?.products_title}
                                                text={p?.products_text}
                                                url={p?.products_affiliateurl}
                                                slug={p?.products_slug}
                                                featuredImg={p?.products_featuredImg}
                                            />
                                        </div>
                                    ))
                                    :
                                    <div className="col-lg-4 col-md-6 col-12 col-12">Not Found</div>
                                    : null}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
